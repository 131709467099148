import React from "react";
import { useState } from "react";
import {
  agregarBoletoAdicional,
  agregarBoletoInicial,
  consultarSituacionBoletos,
  quitarBoleto,
  prepararCompra,
} from "../../funciones/serviciosExteriores/PeticionesServidor";

import Cargando from "../cargando/Cargando";
import { decodificarJWT } from "../../funciones/JWT/decodificarJWT";

import { InputDatosBoletos } from "./componentes/InputDatosBoletos";

//compontes rifa
import { BarraCantidadBoletos } from "./componentes/BarraCantidadBoletos";
import { BoletosMostrados } from "./componentes/BoletosMostrados";
import { NombreRifa } from "./componentes/NombreRifa";
import { FotoRifa } from "./componentes/FotoRifa";
import { DescripcionRifa } from "./componentes/DescripcionRifa";
//termina compontes rifa

//componente Mercado Libre
import { MercadoPago } from "../mercadoPago/MercadoPago";
//termina componente Mercado Libre

import { DesplazarInput } from "./componentes/DesplazarInput";

export const RifaPrueba = (props) => {
let colecionEvento = props.rifa.colecionEvento;
  let DBEvento = props.rifa.DBEvento;
  let nombreRifa = props.rifa.nombreRifa;
  let url = props.rifa.url;
  const [datosObtenidos, modDatosObt] = useState([]);
  const [token, modToken] = useState("");
  const [cargando, modCargando] = useState(false);
  const [boletosSelecc, modBoletosSelecc] = useState([]);
  const [boletosPago, modBoletosPago] = useState([]);
  const [cantidadBoletos, modCantidadBoletos] = useState(0);
  const [total, modTotal] = useState(0);
  const costoBoleto = props.rifa.costoBoleto;
  const [condicionBoletosSeleccion, modCondicionBoletosSeleccion] =
    useState(false);
  const [idCompra, modIdCompra] = useState("");
  const [condicionIdCompra, modCondicionIdCompra] = useState(false);
  const [referenciaMP, modReferenciaMP] = useState("");

  //condicion almenos un boleto seleccionado
  React.useEffect(() => {
    modCondicionBoletosSeleccion(false);

    if (boletosSelecc.length >>> 0) {
      if (token === "") {
        modCondicionBoletosSeleccion(false);
      } else {
        modCondicionBoletosSeleccion(true);
      }
    }
  }, [token, boletosSelecc]);
  /////////////////////////////////////////
  /////////////////////////////////////////

  //condicion idCompra
  React.useEffect(() => {
    modCondicionIdCompra(false);
    if (idCompra === "") {
      modCondicionIdCompra(false);
      modIdCompra("");
    } else {
      modCondicionIdCompra(true);
    }
  }, [idCompra]);
  /////////////////////////////////////////
  /////////////////////////////////////////

  React.useEffect(() => {
    estadoBoletos();
  }, []);
  /////////////////////////////////////////
  /////////////////////////////////////////
  const modificarSeleccion = (boletoID) => () => {
    modCargando(true);
    if (token === "") {
      const consultaBD = agregarBoletoInicial(boletoID, DBEvento, colecionEvento);
      consultaBD.then((respuesta) => {
        if (respuesta["boletosRifa"].length === 1) {
          estadoBoletos();
        } else {
          respuestaConsulta(respuesta);
        }
      });
    } else {
      if (boletosSelecc.includes(boletoID)) {
        const consultaBD = quitarBoleto(boletoID, DBEvento, colecionEvento, token);
        consultaBD.then((respuesta) => {
          respuestaConsulta(respuesta);
        });
      } else {
        if (cantidadBoletos <= 4) {
          const consultaBD = agregarBoletoAdicional(boletoID, DBEvento, colecionEvento, token);
          consultaBD.then((respuesta) => {
            if (respuesta["boletosRifa"].length === 1) {
              estadoBoletos();
            } else {
              respuestaConsulta(respuesta);
            }
          });
        } else {
          alert(
            "Ha excedido el número máximo permitido de boletos seleccionados."
          );
          modCargando(false);
        }
      }
    }
  };

  /////////////////////////////////////////
  /////////////////////////////////////////
  function estadoBoletos() {
    modCargando(true);
    const consulta = consultarSituacionBoletos(DBEvento,colecionEvento);
    consulta.then((respuesta) => {
      respuestaConsulta(respuesta);
    });
  }

  /////////////////////////////////////////
  //PREPARAR COMPRA
  /////////////////////////////////////////
  function compraPreparar(props) {
    modBoletosSelecc([]);
    modIdCompra("");
    const consulta = prepararCompra(
      props.nombre,
      props.celular,
      cantidadBoletos,
      costoBoleto,
      nombreRifa,
      token
    );
    consulta.then((respuesta) => {
      modToken(respuesta["JWT"]);
      modBoletosPago(respuesta["boletosNumero"]);
      modIdCompra(respuesta["idPrefeMercaPago"]);
      modReferenciaMP(respuesta["refOperacion"]);
    });
  }

  /////////////////////////////////////////
  /////////////////////////////////////////

  function respuestaConsulta(respuesta) {
    if (respuesta["JWT"] === "") {
      modDatosObt(respuesta["boletosRifa"]);
    } else {
      modToken(respuesta["JWT"]);
      const JWTDecodificado = decodificarJWT(respuesta["JWT"]);
      const seleccionadosBoletos = JWTDecodificado["objetosSeleccionados"];
      modBoletosSelecc(seleccionadosBoletos);
      modCantidadBoletos(JWTDecodificado["objetosSeleccionados"].length);
      modTotal(JWTDecodificado["objetosSeleccionados"].length * costoBoleto);
      modDatosObt(respuesta["boletosRifa"]);
    }

    modCargando(false);
  }

  return (
    <div>
      {cargando ? <Cargando></Cargando> : <></>}
      <DesplazarInput></DesplazarInput>
      
      <div className="mostrarRifaFoto">
        <FotoRifa fotoRifa={props.rifa.fotoRifa}></FotoRifa>
      </div>
      
      <div className="mostrarDescripcionRifa">
        <DescripcionRifa rifa={props.rifa}></DescripcionRifa>
      </div>

      <div className="contenedorBarra">
        <BarraCantidadBoletos
          cantidad={cantidadBoletos}
          total={total}
        ></BarraCantidadBoletos>
        
      </div>

      {/* 
      <div className="mostrarNombreRifa">
        <NombreRifa nombreRifa={props.rifa.nombreRifa}></NombreRifa>
      </div>
*/}
      
    



      <div className="mostrarBoletos" id="mostrarBoletos">
        {datosObtenidos.map((cadaBoleto) => {
          return (
            <div key={cadaBoleto.idObjeto} className="cadaBoleto">
              <BoletosMostrados
                boleto={cadaBoleto}
                seleccBoletos={boletosSelecc}
                clickBoleto={modificarSeleccion(cadaBoleto.idObjeto)}
              ></BoletosMostrados>
            </div>
          );
        })}
      </div>

      <div className="mostrarInputRifa" id="rifaInput">
        <InputDatosBoletos
          condicionBoletosSeleccion={condicionBoletosSeleccion}
          clickContinuarCompra={compraPreparar}
        ></InputDatosBoletos>
      </div>

      {condicionIdCompra ? (
        <MercadoPago
          idCompra={idCompra}
          referenciaMP={referenciaMP}
          token = {token}
          url = {url}
          boletosPago = {boletosPago}
        ></MercadoPago>
      ) : (
        <></>
      )}




    </div>
  );
};
