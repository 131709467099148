import { Logo } from "./Logo";
import { MenuGeneral } from "../menu/MenuGeneral";

export const BarraPrincipal = (props) => {

return(
<div className="barraPrincipal">

<div>
     
<MenuGeneral
estadoMenuCelular={props.estadoMenuCelular}
clickMenuCelular={props.clickMenuCelular}
clickSubMenuCelular={props.clickSubMenuCelular}
></MenuGeneral>
</div>

<div>
 <Logo></Logo>   
</div>

</div>

);


}