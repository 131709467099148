export const EnHistoria = () => {
    return (
      <div className="imagenInicio">
        <p>
        --Genaro Estrada 
--Juan Carrasco
--Ramon fuentes iturbide
</p>
      </div>
    );
  }