import React from "react";
import {botonPagarCancelar} from "../../funciones/serviciosExteriores/PeticionesServidor";
import { Button } from "primereact/button";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
const llavePublicaMP = process.env.REACT_APP_MERCADOPAGO_LLAVE;

export const MercadoPago = (props) => {
  let idCompra = props.idCompra;
  let token = props.token;
  let url = props.url;
  let boletosPago = props.boletosPago
  console.log(boletosPago)

  React.useEffect(() => {
    initMercadoPago(llavePublicaMP, { locale: "es-MX" });
  }, []);

  /*
  function presBotonpagar() {
    const consulta = botonPagarCancelar(token,process.env.REACT_APP_PRES_BOTON_PAGAR);
    consulta.then((respuesta) => {
      
    });
  }
*/

  function presBotonCancelar() {
    const consulta = botonPagarCancelar(token,process.env.REACT_APP_PRES_BOTON_CANCELAR);
    consulta.then((respuesta) => {
      window.location.assign(url);
    });
  }


  return (
    <div className="contenedorMercadoPago">
      <div className="contenedor">
        <div className="contenido">
          <div className="textos">
        <p className="texto">¡Muchas gracias!</p>
        <p className="texto">Los boletos seleccionados son:</p>
        

        {boletosPago.map((cadaBoleto) => {
          return (
            <p className="textoResaltado" >{cadaBoleto}</p>
          );
        })}
        
        <p className="textoInformativo">"Amo Mazatlán", agradece su preferencia.<br></br></p>
        <p className="textoEsperaMercadoPago">(Un momento por favor,<br></br>En breve aparecerá el botón para continuar con Mercardo Pago.)</p>
        </div>
        <div className="detalles">

        <div id="wallet_container" className = "mercadoPago">
        <Wallet
          initialization={{ preferenceId: idCompra }}
          customization={{
            texts: {
              action: "pay",
              valueProp: "security_details",
            },
            visual: {
              buttonHeight:'3.5rem',
          },
            
          }}
          //onSubmit={(e) => {presBotonpagar()}}
        />
      </div>
        </div>
        

        <div className="detalles">
        
        <Button
              className="botonCancelar"
              type="button"
              label="Cancelar"
              onClick={(e) => {
                presBotonCancelar();
            }}
            />

      </div>
        

        </div>
      </div>
      
      <div>
        
      </div>
    </div>
  );
};
