export const Infraestructura = () => {
  return (
    <div className="imagenInicio">
      <p>
        -- terminal de cruceros -- terminal portuaria -- terminal marítima
        (ferri) --parque aeroespacial -- centros logísticos e industriales --
        aeropuerto --carreteras --tren --presa picachos --terminal autobuses
        --marina mercante --RFE
      </p>
    </div>
  );
};
