export const Alimentos = () => {
  return (
    <div className="imagenInicio">
      <p>
        -Aguachile -Ceviche Sierra -Taco Gobernador -Menudo -Caguamanta
        -Chorreada -Quekis - Asado (Tostadas Y Gorditas) -Marlín En Escabeche
        -Estofado (Res Y Marlín) -Pescado Zarandeado -Capirotada -Pajaritos
        -Frijoles Puercos --Campechana Caldo Caliente --Pastel De Atún
      </p>
    </div>
  );
}

