export const Personas = () => {
    return (
      <div className="imagenInicio">
        <p>
          *EN EL ARTE:
          *EN EDUCACIÓN:
          *EN LA HISTORIA:
          *EN LOS DEPORTES:
          </p>
      </div>
    );
  }