
import classNames from "classnames";


export const BoletosMostrados = (props) => {



  
  let boletoID = props.boleto.idObjeto;
  let boletoNumero = props.boleto.objetoNumero;
  let boletoEstado = props.boleto.estadoObjeto;
  let boletosSelecc = props.seleccBoletos
  //let boletosSelecc = ["6657ee9c6aece5f9983aea79"]
  let disponible = false
  let noDispobible = false
  let seleccionado = false


  if (boletoEstado === "0") {
    
  disponible = true
  noDispobible =  false
  seleccionado = false
  }

  if (boletoEstado === "1" || boletoEstado === "2" || boletoEstado === "3" || boletoEstado === "4" || boletoEstado === "5") {
    
    if (boletosSelecc.includes(boletoID)) {
      disponible = false
      noDispobible = false
      seleccionado = true
    }
    else{
      disponible = false
      noDispobible = true
      seleccionado = false
    }
      
    }
  

 





  const funcionClickBoleto = (event) => {
    const buttonElement = document.getElementById(boletoID);
    buttonElement.blur();
    event.stopPropagation();
    
    
  };

  return (
    <div className="boletosMostrados">
      <div className="contenedorBoletos">

      <div>
      
      <button
              id={boletoID}
              type="button"
              disabled={noDispobible}
              onClick={(e) => {
                funcionClickBoleto(e);
                props.clickBoleto(e); 
              }}
              className={classNames({
                botonDisponible: disponible,
                botonNoDisponible: noDispobible,
                botonSeleccionado: seleccionado,
              })}
            >
              <p className="letrasBoletos">{boletoNumero}</p>
            </button>
      </div>


        
        
      </div>
    </div>
  );
};
