import React from "react";
import { useLocation } from "react-router-dom";
import { consultaEvento } from "../../funciones/serviciosExteriores/PeticionesServidor";
import Cargando from "../cargando/Cargando";
import { useState } from "react";
import { Button } from "primereact/button";

export const RespuestaPagoRifa = () => {
  const URLActual = useLocation();
  const variablesURL = new URLSearchParams(URLActual.search);
  const [cargando, modCargando] = useState(false);
  const [datosObtenidos, modDatosObt] = useState([]);
  const [condicionRespuesta, modCondicionRespuesta] = useState(false);

  React.useEffect(() => {
    respuestaPago();
  }, []);

  function respuestaPago() {
    modCargando(true);
    const consulta = consultaEvento(
      variablesURL.get("token"),
      process.env.REACT_APP_RESP_PAGO
    );
    consulta.then((respuesta) => {
      respuestaConsulta(respuesta);
    });
  }

  function respuestaConsulta(respuesta) {
    if (respuesta["respuesta"].length === 0) {
      modCondicionRespuesta(false);
      modDatosObt([]);
    } else {
      modDatosObt(respuesta["respuesta"]);
      modCondicionRespuesta(true);
    }
    modCargando(false);
  }

  const descargarPDF = (event) => {
    window.open(datosObtenidos.URL, "_blank", "noopener,noreferrer");
    event.stopPropagation();
  };

  return (
    <div className="respuestaPago">
      {cargando ? <Cargando></Cargando> : <></>}

      {condicionRespuesta ? (
        <div className="contenedor">
          <div className="contenido">
            <p className="nota">
              Una disculpa si no recibe en su WhatsApp notificaciones por parte
              de Amo Mazatlán, las políticas de SPAM de WhatsApp son muy
              estrictas, y en ocasiones pueden llegar a bloquear nuestros
              mensajes. Si es su caso, le invitamos a descargar su comprobante
              de forma manual.
            </p>
            <br></br>
            <p className="titulo">¡MUCHAS GRACIAS POR SU CONFIANZA!</p>
            <p className="textoResaltado">{datosObtenidos.nombreCliente}</p>
            <Button
              className="continuarBoton"
              type="button"
              label="DESCARGAR COMPROBANTE"
              onClick={descargarPDF}
            />

            <p className="titulo">RIFA:</p>
            <p className="textoResaltado">{datosObtenidos.nombreEvento}</p>

            <p className="titulo">BOLETOS:</p>

            {datosObtenidos.boletosNumeros.map((element) => {
              return (
                <p key={element} className="boletos">
                  {element}
                </p>
              );
            })}

            <p className="titulo">TOTAL:</p>
            <p className="textoResaltado">{datosObtenidos.costoTotal}</p>
            <p className="titulo">REFERENCIA:</p>
            <p className="textoResaltado">{datosObtenidos.referencia}</p>
            <p className="titulo">ID PAGO:</p>
            <p className="textoResaltado">{datosObtenidos.idOpeMercaPago}</p>

            <p className="titulo">
              El seguimiento de este evento, se le notificará por WhatsApp.
            </p>
            <p className="titulo">
              Si tiene alguna inconsistencia, puede comunicarse con Amo Mazatlán
              al WhatsApp 6692-652-159.
            </p>
          </div>
        </div>
      ) : (
        <div className="contenedor">
          <div className="contenido">
            <p className="titulo">
              Disculpe el inconveniente. Existió un error, o su información no
              es correcta.{" "}
            </p>
            <p className="titulo">¡MUCHAS GRACIAS!</p>
          </div>
        </div>
      )}
    </div>
  );
};
