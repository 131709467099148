import Cookies from "universal-cookie";

const cookies = new Cookies(null, { path: "/" });

export function actualizarCookie(JWTactulizado) {
  cookies.set("JWT", JWTactulizado);
  return;
}

export function eliminarCookie() {
  cookies.remove("JWT");
  return;
}


export function valorCookie(){
    return cookies.get("JWT")
}


export function VerificarCookies() {
  if (cookies.get("JWT")) {
return true  

} else {
    eliminarCookie();
    return false;
  }
}
