export const Poblados = () => {
    return (
      <div className="imagenInicio">
        <p>
        -el quelite
-la noria
-recodo
 </p>
      </div>
    );
  }