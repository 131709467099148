export const Rifas = () => {
    return (
      <div className="imagenInicio">
        <p>
prueba y gusto al gusto
  
        </p>
      </div>
    );
  };
  