export const Transporte = () => {
  return (
    <div className="imagenInicio">
      <p>
        -pulmonia --catamaran --camiones fiesta. -- yate -- helicoptero.
        --pulmonia --zafari --auriga --taxi --tractor (isla piedra)
        --embarcacion menor (panga) --ferry
      </p>
    </div>
  );
};
