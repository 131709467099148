export const EnEducacion = () => {
    return (
      <div className="imagenInicio">
        <p>
        ¡Orgullo porteño! Alumnas de Prepa Vasconcelos ganan oro en Túnez
Con su proyecto de bioplástico con cáscaras de camarón, las estudiantes Natalia Quetzalli Martínez González, Samantha Guadalupe Carrasco Tovar y Génesis Ayón Loza, así como su mentora la profesora Judith Rebeca González Muñoz, ponen en alto el nombre de Mazatlán, el de Sinaloa y el de México
        </p>

        <p>
        ¡https://www.debate.com.mx/mazatlan/Medalla-de-oro-Destacan-alumnos-de-la-UAdeO-Unidad-Mazatlan-en-concurso-internacional-20220318-0361.html
        </p>
      </div>
    );
  }