import * as React from "react";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "../componentesGenerales/plantilla.scss";
import { Footer } from "../paginas/footer/Footer";
import { BarraPrincipal } from "../paginas/barraPrincipal/BarraPrincipal";


//import { decodificarJWT } from "../../reglasNegocio/decodificarJWT";

//import PeticionJWT from "../../serviciosExteriores/PeticionJWT";

const Layout = () => {

  const [estadoMenuCelular, cambiarEstadoMenuCelular] = useState(false);
  let clickMenu = false;

  React.useEffect(() => {

      //llamada a servidor para conocer numero de registros
  
  }, []);

  const clickCualquierParte = () => {
    if (!clickMenu) {
      cambiarEstadoMenuCelular(false);
    }
    clickMenu = false;
  };

  const clickMenuCelular = () => {
    cambiarEstadoMenuCelular((estadoAnterior) => !estadoAnterior);
    clickMenu = true;
  };

  const clickSubMenuCelular = () => {
    cambiarEstadoMenuCelular((estadoAnterior) => !estadoAnterior);
    clickMenu = true;
  };

  return (
    
      <div className="general" onClick={clickCualquierParte}>
        
        <div className="contenedorHeader">
        
        <BarraPrincipal  
        estadoMenuCelular={estadoMenuCelular}
        clickMenuCelular={clickMenuCelular}
        clickSubMenuCelular={clickSubMenuCelular}></BarraPrincipal>
        
        
        </div>
        
        <div className="contenedorContenido">
        <Outlet /> 
        
        </div>


        <div className="contenedorFooter">
        <Footer></Footer>
        
        </div>
    
      </div>
   
  );
};

export default Layout;
