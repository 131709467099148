export const Playas = () => {
  return (
    <div className="imagenInicio">
      <p>
        -Estrella del mar --Isla de la piedra -- de los chivos --olas altas
        --pinitos --norte 7 secciones --camaron --gaviotas 4 secciones --sabalo
        2 secciones --pato blanco --cerritos 7 secciones --bruja --playa punta
        cerritos --playa marmol.
      </p>
    </div>
  );
};
