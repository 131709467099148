


export const Inicio = () => {
    
      return (

        <div className="imagenInicio">
         <br></br>
         <br></br> 
        <h1>¡GRACIAS POR SU INTERES!</h1>
        <h1>LA APLICACIÓN SE ENCUENTRA EN CONSTRUCCIÓN.</h1>
      </div>
    );
}
