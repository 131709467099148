import NIRCelular from "../../datos/celular/NIRCelular.json";

////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
const ValidarDigito = (digito:string):boolean => {
    if (/^[0-9]/.test(digito)) {
      return true;
    } else {
      return false;
    }
  };

////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
  const ValidarLada = (celular:string):string => {
    let lada = celular.substring(0, 2);

    if (lada === "55" || lada === "56" || lada === "33" || lada === "81") {
      return lada;
    } else {
      lada = celular.substring(0, 3);

      let busqueda = NIRCelular.find((jsonArray) => jsonArray.NIR === lada)
        ? lada
        : "false";

      return busqueda;
    }
  };


  const ValidarCelular = (celular:string):string => {

    const valorRetonar = ""
   const digito1 = celular.substring(1, 2);
    if (ValidarDigito(digito1)) {
     const digito2 = celular.substring(2, 3);
      if (ValidarDigito(digito2)) {
       const digito3 = celular.substring(3, 4);
        if (ValidarDigito(digito3)) {
         const digito4 = celular.substring(6, 7);
          if (ValidarDigito(digito4)) {
           const digito5 = celular.substring(7, 8);
            if (ValidarDigito(digito5)) {
             const digito6 = celular.substring(8, 9);
              if (ValidarDigito(digito6)) {
               const digito7 = celular.substring(10, 11);
                if (ValidarDigito(digito7)) {
                 const digito8 = celular.substring(11, 12);
                  if (ValidarDigito(digito8)) {
                   const digito9 = celular.substring(13, 14);
                    if (ValidarDigito(digito9)) {
                     const digito10 = celular.substring(14, 15);
                      if (ValidarDigito(digito10)) {
                        const numCelular =
                         digito1 +
                         digito2 +
                         digito3 +
                         digito4 +
                         digito5 +
                         digito6 +
                         digito7 +
                         digito8 +
                         digito9 +
                         digito10;

                         return numCelular
                        
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return valorRetonar
  }





  export {
    ValidarDigito,ValidarLada, ValidarCelular
  };