export const Patrimonio = () => {
  return (
    <div className="imagenInicio">
      <p>
        -faro --paseo del centenario --angela peralta --plazuela machado
        --alberca olivera -malecon -show lobos marinos
      </p>
    </div>
  );
};
