export const AcercaDe = () => {
  return (
    <div className="imagenInicio">
      <p>
      geografia
clima
flora y fauna
economia
gobierno
-region naval
--region militar
--guardia nacional.
--administracion

      </p>
    </div>
  );
};
