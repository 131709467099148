export const Footer = () => {
 
  const contactoWhatsApp = () => {
    let linkWhatsApp = "https://wa.me/+5216692652159";
    window.open(linkWhatsApp, "_blank", "noopener,noreferrer");
  };
 
  return (
    <div className="footer">
      <div className="gridColumnas">
        <div className="gridFacebook">
          <form action="https://www.facebook.com/amomazatlan1">
            <button type="submit" className="botonesMenu">
              <div className="logoOrganizadores">
                <img
                  src={"multimedia/redesSociales/facebook.svg"}
                  alt="facebook.com/amomazatlan1"
                />
              </div>
            </button>
          </form>
        </div>

        <div className="gridAmoMazatlan">
        
        <iframe src="https://www.facebook.com/plugins/like.php?locale=es_ES&href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61566265012628&width=450&layout=button_count&action=like&size=large&share=true&height=70&appId=1236614747376987" width="230" height="35" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" style={{border:"none"}} title="facebook" ></iframe>
        </div>

        <div className="gridTikTok">
          <form action="https://www.tiktok.com/@amo.mazatlan?_t=8qlm8U6Vq6Q&_r=1">
            <button type="submit" className="botonesMenu">
              <div className="logoOrganizadores">
                <img src={"multimedia/redesSociales/tictokNegro.svg"} alt="tiktok" />
              </div>
            </button>
          </form>
        </div>
        
        <div className="gridWhatsApp">
          
            <button className="botonesMenu" onClick={() => contactoWhatsApp()}>
              <div className="logoOrganizadores">
                <img src={"multimedia/redesSociales/whatsApp.svg"} alt="whatsApp" />
              </div>
            </button>
            </div>
      </div>
    </div>
  );
};
