export const BarraCantidadBoletos = (props) => {
    let cantidad = props.cantidad;
    let total = props.total


    return (
        <div className="barraCantidad">
            <span className="texto">Seleccionados: <strong>{cantidad}</strong>.</span>
            <span className="texto">Total: $<strong>{total}</strong> pesos.</span>
            
        </div>
    );
};
