

export const FotoRifa = (props) => {
  let fotoRifa = props.fotoRifa;
    // let fotoRifa = props.fotoRifa
  let foto1 = "multimedia/rifa/" + fotoRifa + "/1.jpg"; //300*400
  let foto2 = "multimedia/rifa/" + fotoRifa + "/2.jpg"; //300*400
  //let foto3 = "multimedia/rifa/" + fotoRifa + "3.jpg"; //600*800
  //let foto4 = "multimedia/rifa/" + fotoRifa + "4.jpg"; // 1000*1200

 
  return (
    <div className="fotoAjuste">
      <picture>

      <source className="fotografiaRifa" srcSet={foto2} media="(max-width: 1200px)"/>
      <img className="fotografiaRifa" src={foto1} alt="veinte mil-1" />
      </picture>
    </div>
  );
};
