const URLconsultaBoletos = process.env.REACT_APP_URL_CONSULTA_BOLETOS;
const usuario = process.env.REACT_APP_USUARIO;
const password = process.env.REACT_APP_PASSWORD;
const URLBoletoInicial = process.env.REACT_APP_AGREGAR_INICIAL;
const URLBoletoAdicional = process.env.REACT_APP_AGREGAR_ADICIONAL;
const URLBoletoQuitar = process.env.REACT_APP_QUITAR_BOLETO;
const URLPrepararCompra = process.env.REACT_APP_PREPARAR_COMPRA;
const URLIniciarSesion = process.env.REACT_APP_INICIAR_SESION;
const URLVerJWTSesion = process.env.REACT_APP_VER_JWT_SESION;
const URLConsulEventosSesion = process.env.REACT_APP_CONS_EVEN_SESION;
const URLFechaEvento = process.env.REACT_APP_FECHA_EVENTO;
const URLEnProcesoEvento = process.env.REACT_APP_EN_PROCESO_EVENTO;
const URLNumGanador = process.env.REACT_APP_NUMERO_GANADOR;
const URLConocerGanador = process.env.REACT_APP_DATOS_GANADORES;

/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
async function consultarSituacionBoletos(eventoDB,coleccionEvento) {
  const URL = URLconsultaBoletos + "?eventoDB="+eventoDB + "&coleccionEvento=" + coleccionEvento;

  try {
    let opciones = {
      method: "POST",
      headers: {
        //"Content-Type": "application/json",
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:
        "grant_type=&username=" +
        usuario +
        "&password=" +
        password +
        "&scope=&client_id=&client_secret=",
    };

    const peticion = respuestaServerRifa(URL, opciones);

    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}

/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////

async function agregarBoletoInicial(boletoID,eventoDB,coleccionEvento) {
  const URL = URLBoletoInicial + "?eventoDB="+eventoDB + "&coleccionEvento=" + coleccionEvento + "&idObjeto=" + boletoID;

  try {
    let opciones = {
      method: "POST",
      headers: {
        //"Content-Type": "application/json",
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:
        "grant_type=&username=" +
        usuario +
        "&password=" +
        password +
        "&scope=&client_id=&client_secret=",
    };

    const peticion = respuestaServerRifa(URL, opciones);

    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}


/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
async function agregarBoletoAdicional(boletoID, eventoDB,coleccionEvento, JWT) {
  const URL = URLBoletoAdicional + "?eventoDB="+eventoDB + "&coleccionEvento=" + coleccionEvento + "&idObjeto=" + boletoID;

  try {
    let opciones = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JWT}`,
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respuestaServerRifa(URL, opciones);

    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}


/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
async function quitarBoleto(boletoID, eventoDB,coleccionEvento, JWT) {
  const URL = URLBoletoQuitar + "?eventoDB="+eventoDB + "&coleccionEvento=" + coleccionEvento + "&idObjeto=" + boletoID;

  try {
    let opciones = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JWT}`,
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respuestaServerRifa(URL, opciones);

    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}


/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
async function prepararCompra(nombreCliente, celularCliente, cantidadBoletos, costoBoleto, nombreRifa, JWT) {
  const URL = URLPrepararCompra + "?nombre=" + nombreCliente + "&celular=" + celularCliente + "&cantidad=" + cantidadBoletos + "&costo=" + costoBoleto+ "&nombreEvento=" + nombreRifa;
//http://89.116.212.55:8001/prepararCompra/?coleccionEvento=qweeq12&nombre=carlos%20fernandez%20ramirez&celular=5588996633&cantidad=3&costo=69&nombreRifa=gusto%20al%20gusto
  try {
    let opciones = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JWT}`,
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respuestaServerRifa(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}
/////////////////////////////////////////
/////////////////////////////////////////
function botonPagarCancelar(JWT,URL) {
  try {
    let opciones = {
      method: "POST",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${JWT}`
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respuestaServerRifa(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}

/////////////////////////////////////////
/////////////////////////////////////////
function consultaEvento(JWT,URL) {
  try {
    let opciones = {
      method: "POST",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${JWT}`
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    
    const peticion = respGeneralServer(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}

/////////////////////////////////////////
//iniciar sesion
/////////////////////////////////////////
async function iniciarSesion(usuario, password) {
  const URL = URLIniciarSesion;

  try {
    let opciones = {
      method: "POST",
      headers: {
        //"Content-Type": "application/json",
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:
        "grant_type=&username=" +
        usuario +
        "&password=" +
        password +
        "&scope=&client_id=&client_secret=",
    };

    const peticion = respGeneralServer(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}

/////////////////////////////////////////
/////////////////////////////////////////
async function verificarJWTSesion(JWT) {
  const URL = URLVerJWTSesion;
  try {
    let opciones = {
      method: "POST",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${JWT}`
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respGeneralServer(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}


/////////////////////////////////////////
/////////////////////////////////////////
async function consulEventosSesion(JWT) {
  const URL = URLConsulEventosSesion;
  try {
    let opciones = {
      method: "POST",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${JWT}`
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

      
    const peticion = respGeneralServer(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}

/////////////////////////////////////////
/////////////////////////////////////////
async function FechaEvento(fecha,idObjeto,nombreEvento,eventoDB,JWT) {

  const URL = URLFechaEvento +"?fecha=" + fecha + "&idObjeto="+idObjeto + "&nombreEvento=" + nombreEvento + "&eventoDB=" + eventoDB;
  try {
    let opciones = {
      method: "POST",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${JWT}`
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respGeneralServer(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}


/////////////////////////////////////////
/////////////////////////////////////////
async function enProcesoEvento(linkEnVivo,idObjeto,nombreEvento,eventoDB,JWT) {

  const URL = URLEnProcesoEvento +"?linkEnVivo="+linkEnVivo +"&idObjeto="+idObjeto + "&nombreEvento=" + nombreEvento + "&eventoDB=" + eventoDB;
  try {
    let opciones = {
      method: "POST",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${JWT}`
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respGeneralServer(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}

/////////////////////////////////////////
/////////////////////////////////////////
async function numGanador(numGanador,idObjeto,nombreEvento,eventoDB,JWT) {

  const URL = URLNumGanador +"?numGanador=" + numGanador + "&idObjeto="+idObjeto + "&nombreEvento=" + nombreEvento + "&eventoDB=" + eventoDB;
  try {
    let opciones = {
      method: "POST",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${JWT}`
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respGeneralServer(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}


/////////////////////////////////////////
/////////////////////////////////////////
async function conocerDatosGanadores(idObjeto,nombreEvento,eventoDB,JWT) {

  const URL = URLConocerGanador +"?idObjeto="+idObjeto + "&nombreEvento=" + nombreEvento + "&eventoDB=" + eventoDB;
  try {
    let opciones = {
      method: "POST",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${JWT}`
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const peticion = respGeneralServer(URL, opciones);


    return peticion;
  } catch (error) {
    window.alert("Falla con el servidor, favor de reintentar");

    return;
  }
}

////////////////////////////////////////
//termina iniciar sesion
/////////////////////////////////////////

/////////////////////////////////////////






/////////////////////////////////////////
//consulta y respuesta
/////////////////////////////////////////
async function respuestaServerRifa(URL, opciones) {
  try {
    const consulta = await fetch(URL, opciones);
    if (consulta.ok) {
      if (consulta.status === 201) {
        const respuesta = await consulta.json();
        return respuesta;
      }

      if (consulta.status === 204) {
        window.alert("Este boleto esta en proceso de compra por otro usuario.");
        return {"JWT":"", "boletosRifa": ["procesoCompra"]}
      }
    } else {
      const respuesta = await consulta.json();
      window.alert(respuesta.detail);
      return {"JWT":"", "boletosRifa": []}
    }
  } catch (error) {
    window.alert("Hubo un error");

    return {"JWT":"", "boletosRifa": []}
  }
}



/////////////////////////////////////////
/////////////////////////////////////////
async function respGeneralServer(URL, opciones) {
  try {
    const consulta = await fetch(URL, opciones);
    

    if (consulta.ok) {
  
        const respuesta = await consulta.json();
        
        return respuesta;
      

    } else {
      const respuesta = await consulta.json();
      window.alert(respuesta.detail);
      return {"respuesta": []}
    }
  } catch (error) {
    window.alert("Hubo un error");
    return {"respuesta": []}
  }
}


export {
  consultarSituacionBoletos,
  agregarBoletoInicial,
  agregarBoletoAdicional,
  quitarBoleto,prepararCompra,
  botonPagarCancelar,
  consultaEvento,
  iniciarSesion,
  verificarJWTSesion,
  consulEventosSesion,
  FechaEvento,
  enProcesoEvento,
  numGanador,
  conocerDatosGanadores
};
