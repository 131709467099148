export const Gastronomia = () => {
    return (
      <div className="imagenInicio">
        <p>
         *ALIMENTOS:
         *BEBIDAS:
         *DULCES y POSTRES:
        </p>
      </div>
    );
  }