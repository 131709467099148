import React from "react";
import { useLocation } from "react-router-dom";
import { consultaEvento } from "../../funciones/serviciosExteriores/PeticionesServidor";
import Cargando from "../cargando/Cargando";
import { useState } from "react";

export const ResultadoRifa = () => {
  const URLActual = useLocation();
  const variablesURL = new URLSearchParams(URLActual.search);
  const [cargando, modCargando] = useState(false);
  const [datosObtenidos, modDatosObt] = useState([]);
  const [condicionRespuesta, modCondicionRespuesta] = useState(false);

  React.useEffect(() => {
    consultaResultado();
  }, []);

  function consultaResultado() {
    modCargando(true);
    const consulta = consultaEvento(
      variablesURL.get("token"),
      process.env.REACT_APP_RESUL_EVENTO
    );
    consulta.then((respuesta) => {
      console.log(respuesta);
      respuestaConsulta(respuesta);
    });
  }

  function respuestaConsulta(respuesta) {
    console.log(respuesta);
    if (respuesta["respuesta"].length === 0) {
      console.log("incorrecta");
      modCondicionRespuesta(false);
      modDatosObt([]);
    } else {
      console.log("respuesta correcta");
      modCondicionRespuesta(true);
      let datos = respuesta["respuesta"];
      modDatosObt(datos.eventos);

      console.log(datos.eventos);
    }
    modCargando(false);
  }

  return (
    <div className="respuestaPago">
      {cargando ? <Cargando></Cargando> : <></>}

      {condicionRespuesta ? (
        <div className="contenedor">
          <div className="contenido">
            {/* rifa pendiente*/}
            {/* rifa pendiente*/}
            {datosObtenidos.estatusEvento === "pendiente" && (
              <div>
                <h3>!GRACIAS POR SU INTERES!</h3>
                <br></br>
                <br></br>
                <h2>
                  <strong>
                  TODAVIA NO SE DEFINE, LA FECHA Y EL HORARIO PARA REALIZAR LA
                  RIFA.
                  </strong>
                </h2>
                <br></br>
                <br></br>
                <h3 >
                  LO INVITAMOS A VISITAR NUESTRAS REDES SOCIALES.
                </h3>
              
              </div>
            )}

            {/* fecha definida*/}
            {/* fecha definida*/}
            {datosObtenidos.estatusEvento === "fechaDefinida" && (
              <div>
                <h3>!GRACIAS POR SU INTERES!</h3>
                <br></br>
                <br></br>
                <h3>SE LLEVARÁ ACABO EL DÍA </h3>
                <h2>
                  <strong>
                  {datosObtenidos.detallesEvento}
                  </strong>
                </h2>
                <br></br>
                <br></br>
                <h3>
                  LO INVITAMOS A SEGUIR EL EVENTO EN VIVO EN NUESTRAS REDES
                  SOCIALES.
                </h3>
               
              </div>
            )}

            {/* fecha definida*/}
            {/* fecha definida*/}
            {datosObtenidos.estatusEvento === "enProceso" && (
              <div>
                <h3>!GRACIAS POR SU INTERES!</h3>
                <br></br>
                <br></br>
                <h2>
                  <strong>
                  EL EVENTO SE ESTA LLEVANDO ACABO EN ESTE MOMENTO.{" "}
                  </strong>
                </h2>

                <br></br>
                <br></br>

                <h3>
                  LO INVITAMOS A SEGUIR LA RIFA EN VIVO EN NUESTRAS REDES
                  SOCIALES.
                </h3>

              </div>
            )}

            {/* BOLETO GANADOR PRINCIPAL*/}
            {/* BOLETO GANADOR PRINCIPAL*/}
            {datosObtenidos.estatusEvento === "concluido" &&
              datosObtenidos.premioPrincipal !== "" &&
              datosObtenidos.premioSecundario.length === 0 && (
                // determina los ganadores

                <div>
                  <h1> <strong>!FELICIDADES! GANÓ EL PREMIO PRINCIPAL</strong></h1>
                  <br></br>
                  <h2>CON EL BOLETO NÚMERO: <strong>{datosObtenidos.numeroGanador}</strong></h2>
                
                  <br></br>
                  <br></br>
                  <p className="titulo">RESULTADOS DE LA RIFA:</p>
                  <p className="titulo">PREMIO PRINCIPAL</p>
                  <p className="textoResaltado">
                    
                    BOLETO: {datosObtenidos.numeroGanador}
                  </p>
                  
                  <p className="titulo">PREMIO SECUNDARIO</p>
                  <p className="boletos">BOLETOS:</p>
                  {datosObtenidos.numerosSecundarios.map((element) => {
                    return <p className="boletos"> {element}</p>;
                  })}
                </div>
              )}

            {/* BOLETO GANADOR SECUNDARIO*/}
            {/* BOLETO GANADOR SECUNDARIO*/}
            {datosObtenidos.estatusEvento === "concluido" &&
              datosObtenidos.premioPrincipal === "" &&
              datosObtenidos.premioSecundario.length >= 1 && (
                // determina los ganadores

                <div>
                  
                  <h1> <strong>!FELICIDADES! GANÓ EL PREMIO SECUNDARIO</strong></h1>
                  <br></br>
                  {datosObtenidos.premioSecundario.length === 1 && (
                    <div>
                      <h2 className="textoResaltado">
                      CON EL BOLETO NÚMERO: {datosObtenidos.premioSecundario}
                      </h2>
                    </div>
                  )}

                  {datosObtenidos.premioSecundario.length >> 1 && (
                    <div>
                      <h2>CON LOS BOLETOS:</h2>
                      {datosObtenidos.premioSecundario.map((element) => {
                        return <h2 >{element}</h2>;
                      })}
                    </div>
                  )}
                  <br></br>
                  <p className="titulo">RESULTADOS DE LA RIFA:</p>
                  <p className="titulo">PREMIO PRINCIPAL</p>
                  <p className="textoResaltado">
                    BOLETO: {datosObtenidos.numeroGanador}
                  </p>
                  
                  <p className="titulo">PREMIO SECUNDARIO</p>
                  <p className="boletos">BOLETOS:</p>
                  {datosObtenidos.numerosSecundarios.map((element) => {
                    return <p className="boletos"> {element}</p>;
                  })}
                </div>
              )}

            {/* BOLETO GANADOR PRINCIPAL Y SECUNDARIO*/}
            {/* BOLETO PRINCIPAL Y SECUNDARIO*/}
            {datosObtenidos.estatusEvento === "concluido" &&
              datosObtenidos.premioPrincipal !== "" &&
              datosObtenidos.premioSecundario.length >= 1 && (
                // determina los ganadores

                <div>
                  <h1> <strong>!FELICIDADES! GANÓ EL PREMIO PRINCIPAL Y EL PREMIO SECUNDARIO</strong></h1>
                  <br></br>
                  <h2>CON LOS BOLETOS: {datosObtenidos.numeroGanador}</h2>
                  <h2>{datosObtenidos.premioPrincipal}</h2>
                  {datosObtenidos.premioSecundario.map((element) => {
                    return <h2>{element}</h2>;
                  })}
                  <br></br>
                  <p className="titulo">RESULTADOS DE LA RIFA:</p>
                  <p className="titulo">PREMIO PRINCIPAL</p>
                  <p className="textoResaltado">
                    BOLETO: {datosObtenidos.numeroGanador}
                  </p>
                  
                  <p className="titulo">PREMIO SECUNDARIO</p>
                  <p className="boletos">BOLETOS:</p>
                  {datosObtenidos.numerosSecundarios.map((element) => {
                    return <p className="boletos"> {element}</p>;
                  })}
                </div>
              )}

            {/* BOLETO NO GANADOR*/}
            {/* BOLETO NO GANADOR*/}
            {datosObtenidos.estatusEvento === "concluido" &&
              datosObtenidos.premioPrincipal === "" &&
              datosObtenidos.premioSecundario.length === 0 && (
                // determina los ganadores

                <div>
                  <h2>¡GRACIAS POR SU PARTICIPACIÓN!</h2>
                  <h2><strong>DESAFORTUNADAMENTE NO FUE GANADOR.</strong></h2>
                  <br></br>
                  <br></br>
                  <p className="titulo">RESULTADOS DE LA RIFA:</p>
                  <p className="titulo">PREMIO PRINCIPAL</p>
                  <p className="textoResaltado">
                    BOLETO: {datosObtenidos.numeroGanador}
                  </p>
                  
                  <p className="titulo">PREMIO SECUNDARIO</p>
                  <p className="boletos">BOLETOS:</p>
                  {datosObtenidos.numerosSecundarios.map((element) => {
                    return <p className="boletos"> {element}</p>;
                  })}
                </div>
              )}
          </div>
        </div>
      ) : (
        <div className="contenedor">
          <div className="contenido">
          <h3>!GRACIAS POR SU INTERES!</h3>
                <br></br>
                <br></br>
          <h2>DISCULPE EL INCONVENIENTE. EXISTIÓ UN ERROR, O SU INFORMACIÓN NO ES CORRECTA.</h2>
          
        </div>
        </div>
      )}
    </div>
  );
};
