


export const Logo = () => {


    return (
    <div className="logo">
      <img
                  src={"multimedia/logo/amomazatlan.svg"}
                  alt="https://amomazatlan.com"
                />
    </div>
  );
};
