

export function ControlTamanioLetra () {
//tamanio celular
    if (window.innerWidth <= 450) {
      document.documentElement.style.fontSize = "18 px";
    }
//tamanio celular grande o tablet pequenia
    if (window.innerWidth >= 451 && window.innerWidth <= 590) {
      document.documentElement.style.fontSize = "17 px";
    }
//tamanio tablet
    if (window.innerWidth >= 591 && window.innerWidth <= 1120) {
      document.documentElement.style.fontSize = "16 px";
    }
//tamanio laptop
    if (window.innerWidth >= 1121) {
      document.documentElement.style.fontSize = "14 px";
    }
}
