export const Premios = () => {
    return (
      <div className="imagenInicio">
        <p>
        -13 maravillas de mexico
-record guiner coctel
</p>
      </div>
    );
  }
  