export const DescripcionRifa = (props) => {
  let premioPrincipal = props.rifa.premioPrincipal;
  let premioSecundario = props.rifa.premioSecundario;

  return (
    <div className="contenedorDescripcion">
      <div className="bordesuperior"></div>
      <div className="rifaDescripcion">
        
      <span className="subtitulo">NOMBRE DE LA RIFA: <br></br></span>
        <div className="detalle">
        <span >{props.rifa.nombreRifa}<br></br><br></br></span>
        </div>


        <span className="subtitulo">PREMIO PRINCIPAL:<br></br></span>
        <div className="detalle">
        {premioPrincipal.map((descripcionPremio) => {
          return <span key={descripcionPremio}>{descripcionPremio}<br></br></span>;
        })}
        </div>
        <span className="subtitulo"><br></br>PREMIO SECUNDARIO:<br></br></span>
        
        <div className="detalle">
        {premioSecundario.map((descripcionPremio) => {
          return <span key={descripcionPremio}>{descripcionPremio}<br></br></span>;
        })}
        </div>

        <span className="subtitulo"><br></br>COSTO DEL BOLETO:<br></br></span>
        <div className="detalle">
        <span >${props.rifa.costoBoleto}.00 PESOS.<br></br></span>
        </div>
        <span className="subtitulo"><br></br>FECHA Y HORARIO DE LA RIFA:<br></br></span>
        <div className="detalle">
        <span >{props.rifa.fechaRifa}<br></br></span>
        </div>
       
        <span className="subtitulo"><br></br>MODALIDAD DE LA RIFA:<br></br></span>
        <div className="detalle">
        <span>{props.rifa.rifaModalidad}<br></br></span>
        </div>

        <span className="subtitulo"><br></br>SEGUIMIENTO DE LA RIFA:<br></br></span>
        <div className="detalle">
        <span>{props.rifa.rifaSeguimiento}<br></br></span>
        </div>

        <span className="subtitulo"><br></br>ENTREGA DE PREMIACIÓN:<br></br></span>
        <div className="detalle">
        <span>{props.rifa.premioEntrega}<br></br></span>
        </div>
       
      </div>
      <div className="bordeInferior"></div>
    </div>
  );
};
