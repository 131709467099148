export const EventosRepresentativos = () => {
  return (
    <div className="imagenInicio">
      <p>
        carnaval semana de la moto semana de la troca feria ganadera callejeada
        día de muertos cabalgata combate naval semana bocho
      </p>
    </div>
  );
};
