//funcionaminto REACT
import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
//Termina funcionaminto REACT
//----------------------------------------

import Layout from "./componentesGenerales/Layout";
import { ControlTamanioLetra } from "./funciones/letra/ControltamanioLetra";

import "./componentesGenerales/plantilla.scss";


//rifas
import { Rifas } from "./paginas/Rifas/Rifas";
import {RifaPrueba} from "./paginas/Rifas/RifaPrueba";
import { RespuestaPagoRifa } from "./paginas/Rifas/RespuestaPagoRifa";
import { ResultadoRifa } from "./paginas/Rifas/ResultadoRifa";
//termina rifas

//----------------------------------------

//Modificar Eventos
import { ModRifas } from "./paginas/modEventos/ModRifas";

//Termina modificar eventos



//Modificar terminos
import { Terminos } from "./paginas/terminos/Terminos"; 

//Termina modificar terminos


//Modificar terminos
import { Instrucciones } from "./paginas/instrucciones/Instrucciones"; 

//Termina modificar terminos

//relacionados con la pagina mazatlan
import { Alimentos } from "./paginas/Mazatlan/Gastronomia/Alimentos";
import { Bebidas } from "./paginas/Mazatlan/Gastronomia/Bebidas";
import { DulcesPostres } from "./paginas/Mazatlan/Gastronomia/DulcesPostres";
import { Gastronomia } from "./paginas/Mazatlan/Gastronomia/Gastronomia";
import { Personas } from "./paginas/Mazatlan/Personas/Personas";
import { EnArte } from "./paginas/Mazatlan/Personas/EnArte";
import { EnDeportes } from "./paginas/Mazatlan/Personas/EnDeportes";
import { EnEducacion } from "./paginas/Mazatlan/Personas/EnEducacion";
import { EnHistoria } from "./paginas/Mazatlan/Personas/EnHistoria";
import { AcercaDe } from "./paginas/Mazatlan/AcercaDe";
import { BandaSin } from "./paginas/Mazatlan/BandaSin";
import { CentrosYLugares } from "./paginas/Mazatlan/CentrosYLugares";
import { Deportes } from "./paginas/Mazatlan/Deportes";
import { EventosRepresentativos } from "./paginas/Mazatlan/EventosRepresentativos";
import { Historia } from "./paginas/Mazatlan/Historia";
import { Infraestructura } from "./paginas/Mazatlan/Infrastructura";
import { Lexico } from "./paginas/Mazatlan/Lexico";
import { Mazatlan } from "./paginas/Mazatlan/Mazatlan";
import { Monumentos } from "./paginas/Mazatlan/Monumentos";
import { Patrimonio } from "./paginas/Mazatlan/Patrimonio";
import { Playas } from "./paginas/Mazatlan/Playas";
import { Poblados } from "./paginas/Mazatlan/Poblados";
import { Premios } from "./paginas/Mazatlan/Premios";
import { Transporte } from "./paginas/Mazatlan/Transporte";
import { Trascendentes } from "./paginas/Mazatlan/Trascendentes";
import { ProxiEventos } from "./paginas/ProximosEventos/ProxiEventos";
import { Contacto } from "./paginas/Mazatlan/Contacto/Contacto";
//termina relacionados con la pagina mazatlan
import { Inicio} from "./componentesGenerales/Inicio"

//variable rifas
import RifaInicial from "./datos/Rifas/66a0502930cb50cf291b8508.json"
import Rifa20Mil_1 from "./datos/Rifas/673a3e860faa5ef5dad70f21.json"
//termina variable rifas

const App = () => {
 
  React.useEffect(() => {
    ControlTamanioLetra();

  }, []);


  

  return (

   
    <Routes>
      
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<RifaPrueba rifa={Rifa20Mil_1}/>} />

        {/*paginas rifas */}
        {/*paginas rifas */}
        <Route path="/Rifas" element={<Rifas />} />
        <Route path="/rifaInicial" element={<RifaPrueba rifa={RifaInicial}/>} />
        <Route path="/20mil-1" element={<RifaPrueba rifa={Rifa20Mil_1}/>} />
        <Route path="/respuestaPago" element={<RespuestaPagoRifa/>} />
        <Route path="/resultadoRifa" element={<ResultadoRifa/>} />
        {/* termina paginas rifas */}
        {/*termina paginas rifas */}

        {/*----------------------------------------*/}
       
        {/*modificar eventos */}
        <Route path="/modRifas" element={<ModRifas/>} />
        {/*termina modificar eventos */}

        {/*----------------------------------------*/}
       
        {/*modificar terminos */}
        <Route path="/terminos" element={<Terminos/>} />
        {/*termina modificar terminos */}

        {/*modificar instrucciones */}
        <Route path="/instrucciones" element={<Instrucciones/>} />
        {/*termina modificar instrucciones */}




        {/*----------------------------------------*/}

        {/*paginas relacionadas con Mazatlan */}
        {/*paginas relacionadas con Mazatlan */}
        <Route path="/Alimentos" element={<Alimentos/>} />
        <Route path="/Bebidas" element={<Bebidas/>} />
        <Route path="/DulcesYPostres" element={<DulcesPostres/>} />
        <Route path="/Gastronomia" element={<Gastronomia/>} />
        <Route path="/Personas" element={<Personas/>} />
        <Route path="/EnArte" element={<EnArte/>} />
        <Route path="/EnDeportes" element={<EnDeportes/>} />
        <Route path="/EnEducacion" element={<EnEducacion/>} />
        <Route path="/EnHistoria" element={<EnHistoria/>} />
        <Route path="/AcercaDe" element={<AcercaDe/>} />
        <Route path="/BandaSin" element={<BandaSin/>} />
        <Route path="/CentrosYLugares" element={<CentrosYLugares/>} />
        <Route path="/Deportes" element={<Deportes/>} />
        <Route path="/EventosRepresentativos" element={<EventosRepresentativos/>} />
        <Route path="/Historia" element={<Historia/>} />
        <Route path="/Infraestructura" element={<Infraestructura/>} />
        <Route path="/Lexico" element={<Lexico/>} />
        <Route path="/Mazatlan" element={<Mazatlan/>} />
        <Route path="/Monumentos" element={<Monumentos/>} />
        <Route path="/Patrimonio" element={<Patrimonio/>} />
        <Route path="/Playas" element={<Playas/>} />
        <Route path="/Poblados" element={<Poblados/>} />
        <Route path="/Premios" element={<Premios/>} />
        <Route path="/Transporte" element={<Transporte/>} />
        <Route path="/Trascendentes" element={<Trascendentes/>} />
        <Route path="/ProxiEventos" element={<ProxiEventos/>} />
        {/*Termina paginas relacionadas con Mazatlan */}
        {/*Termina paginas relacionadas con Mazatlan */}

        <Route path="/Contacto" element={<Contacto/>} />

        
        
        
        
        <Route path="/*" element={<Inicio/>} />
      </Route>
    </Routes>
  );
}

export default App;