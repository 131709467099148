export const Lexico = () => {
    return (
      <div className="imagenInicio">
        <p>
        plebes
bichi
galea
abanico
fierro
boyito
bolis
churritos
tronchito
viejon
panocha

Ahora: Se refiere a que realizarás una actividad durante el día en lugar de decir "hoy haré esto". Ejemplo: "Ahora iré a cenar tacos."


Arre: Expresión de afirmación o acuerdo. Ejemplo: "¿Vamos a la playa?" "- Arre."


Abanico: Término para los ventiladores de techo o ventiladores de pedestal.


Abitachar: Poner a alguien en alerta para que esté atento o actúe con rapidez. Ejemplo: "Los plebes lo abitacharon, si no se hubiera caído..."


Agüitado: Sentirse intensamente triste o cabizbajo. Ejemplo: “Ando agüitado “


Alicusar: Arreglar la apariencia personal para salir o asistir a un evento. Ejemplo: “Alicusate que ya nos tenemos que ir a la fiesta “


Bichi: Desnudo, encuerado. Ejemplo: "Aún no me cambio, ando bichi."


Bobitos:  Insectos más pequeños que las moscas.


Bofeado: Quedarse sin aire después de realizar un gran esfuerzo; estar cansado. Ejemplo: "Ando bien bofeado."


Bote: Manera de referirse a las latas de refresco o cerveza. Ejemplo: “vamos por un botes para el calor “


Ballena: Cerveza en botellas de un litro o caguama. Ejemplo: "Ando con los compas tomando una ballena."


Cachucha: Gorra, especialmente usada en el béisbol.


Cahuamanta: Platillo típico de la región, similar al que se hacía con caguama (tortuga marina), pero sustituyendo la tortuga por mantarraya.


Chaca: Persona considerada la más hábil, influyente o poderosa en alguna actividad. Ejemplo: "Con quien hablé creo que era uno de los chacas, todos se le cuadraban." "Tú eres bien chaca en la empresa."


Calzonear: Apresurar a alguien.


Capear:  Acceder a una petición o esquivar algún golpe, pelar, hacer caso. Ejemplo:  "Me capeo la morra”


Carrilla: Hacer mofa o burla ante un hecho o situación. Ejemplo: “Estaba echando carrilla con los plebes”


Chanza: Una oportunidad o ventaja. Ejemplo: "Le dieron chanza de entrar tarde”


Chiltepín: Chile pequeño en forma de bolita, muy picante.


Chompa: Cabeza.


Chilo: Algo que gusta o que "está chingón", sinónimo de "chido". Ejemplo: "Qué chilos están tus lentes"


Chirotear: Acción y efecto de divertirse o jugar. Ejemplo: "Los plebes están chiroteando en el parque."


Chirudo: Cuando un objeto está gastado, deshilachado o desgarrado.


Chirri: Que tiene mucha agua o que le falta consistencia.


Chira: Hocico del cochi (cerdo). También se usa coloquialmente para referirse a la boca de las personas.


Chispitear: Lluvia ligera.


Cochi: Cerdo o puerco.


Coricos: Galletas tradicionales de maiz en forma de anillo.


Culei: Cuando una persona se comporta de manera negativa o algo es de baja calidad.


Cura: Risa, alegría, desmadre. Ejemplo “ Estoy agarrando cura con los amigos “


Curado: Curioso, chilo, agradable, divertido.


Cuachalote: Mal vestido, desaliñado o descuidado en su aspecto o trabajo.


Cucho: Que está lastimado o le falta una extremidad.


Despatolar: Hacer que un objeto deje de funcionar correctamente o separar sus piezas.


Engrirse: Encariñarse intensamente con un lugar o persona de tal manera que es difícil desprenderse de ellos.


Feria: Cambio de dinero o monedas.


Fierro: Expresión de aprobación para realizar una actividad o dirigirse a un destino.


Fachoso: Que hace alarde de su belleza o arreglo. Ejemplo: "Es medio fachosilla la novia que trae, se anda luciendo."


Fufurufo: Que se jacta de tener poder o se atreve a hacer cosas sin temor. Ejemplo: "Llegó bien fufurufo y la sacó de su casa."


Garrero: Ropa que se encuentra hecha bola, descuidada.


Golletero: Quiere todo gratis.


Güasa: Rondana, también significa decir algo de broma.


Guasiado: Está loco o habla incoherencias. Ejemplo: "Ese morro está bien guasiado, dice puras tonterias"


Güila: Flaca, desnutrida. “La morra esta muy güila“


Jaba: Caja de madera usada regularmente para frutas y verduras, también se le llama así a la caja de refrescos.


Jondear: Aventar o lanzar un objeto. "Jondea la cubeta."


Joven: Se refiere a una persona joven, ya sea hombre o mujer.


Jálate: Significa "ven" o "lánzate" por algo, también puede usarse la palabra "Jalado."


Jondear: Aventar o lanzar un objeto.


Machaca: Carne asada desmenuzada.


Mandil: Palabra que se utiliza para decir que vas a visitar a tu novia. Ejemplo: "Voy al mandil."


Morra: Palabra que hace referencia a una mujer. Ejemplo: "¿Ya viste a esa morra?"


Plebe: Sinónimo de muchacho o niño. Ejemplo:  "¿Viste cómo baila la plebe?"


Plebada: Conjunto de plebes (niños, adolescentes o jóvenes) o gran concurrencia de éstos en un lugar. Ejemplo: "Aunque la plebada patalee, subirán la tarifa del transporte."


Piñado: Expresión que indica que una persona está muy emocionada con algo. Ejemplo:  "Estoy bien piñado con esa morra."


Pichar: Sinónimo de invitar algo. Ejemplo: "¿Te vas a pichar las galletas? Los plebes picharon las ballenas."


Panga: Embarcación de pequeña eslora comúnmente utilizada para pesca comercial o jalar bananas en la playa.


Sarra: Algo que está horrible o es malo. Ejemplo: "Que sarra está la película."


Vaquetón: Persona floja. Ejemplo: “El morro es un vaquetón”


Túmbate el rollo: Quítate esa idea y dale para adelante.
</p>
      </div>
    );
  }
  