import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { useState } from "react";
import { InputMask } from "primereact/inputmask";
export const EditarEventos = (props) => {
  let idObjeto = props.idObjeto;
  let estatusEvento = props.estatusEvento;
  const [fechas, modFechas] = useState(false);
  const [numGanador, modNumGanador] = useState(false);
  const [enVivo, modEnVivo] = useState(false);

  const fechaPendiente = useFormik({
    initialValues: {
      fecha: "",
    },

    validate: (data) => {
      let errors = {};
      modFechas(false);

      console.log(data.fecha[15]);
      if (data.fecha[15] !== "_" && data.fecha[15] !== undefined) {
        modFechas(true);
      }

      return errors;
    },
  });

  const enVivoLink = useFormik({
    initialValues: {
      linkEnVivo: "",
    },

    validate: (data) => {
      let errors = {};
      modEnVivo(false);

      if (data.linkEnVivo != "") {
        modEnVivo(true);
      }

      return errors;
    },
  });

  const boletoGanador = useFormik({
    initialValues: {
      ganador: "",
      confirmarGanador: "",
    },

    validate: (data) => {
      let errors = {};
      modNumGanador(false);

      if (data.ganador === data.confirmarGanador) {
        modNumGanador(true);
      }

      return errors;
    },
  });

  const clickCerrar = () => {
    props.clickCerrar();
  };

  const clickFechaPendiente = () => {
    const datos = { idObjeto: idObjeto, fecha: fechaPendiente.values.fecha };
    fechaPendiente.resetForm();
    props.clickFechaPendiente(datos);
    props.clickCerrar();
  };

  /*
   const clickModificarFecha = () => {
    const datos = {idObjeto: idObjeto, fecha: fechaPendiente.values.fecha }
    fechaPendiente.resetForm();
    props.clickFechaPendiente(datos)
    props.clickCerrar()
   };
*/
  const clickEnProceso = () => {
    const datos = {
      idObjeto: idObjeto,
      linkEnVivo: enVivoLink.values.linkEnVivo,
    };
    props.clickEnProceso(datos);
    props.clickCerrar();
  };

  const clickConocerGanadores = () => {
    const datos = { idObjeto: idObjeto };
    props.clickConocerGanadores(datos);
    props.clickCerrar();
  };

  const clickBotonGanador = () => {
    const datos = {
      idObjeto: idObjeto,
      numGanador: boletoGanador.values.ganador,
    };
    fechaPendiente.resetForm();
    props.clickBotonGanador(datos);
    props.clickCerrar();
  };

  return (
    <div className="editarEventos">
      <div className="botonCerrar" onClick={clickCerrar}>
        <i className="pi pi-times" />
      </div>

      {/* PENDIENTE*/}
      {/* PENDIENTE*/}
      <div className="mostrarInputRifa">
        <div className="contenedorInput">
          {estatusEvento === "pendiente" && (
            <div className="formulario">
              <form onSubmit={fechaPendiente.handleSubmit}>
                <div className="input">
                  <p className="textoInput">FECHA DEL EVENTO: </p>

                  <InputMask
                    name="fecha"
                    id="fecha"
                    value={fechaPendiente.values.fecha}
                    mask="99/aaa/9999; 99:99 aa"
                    autoClear={false}
                    unmask={false}
                    placeholder="dd/mmm/aaaa; hh:mm pm"
                    onChange={fechaPendiente.handleChange}
                  ></InputMask>
                </div>

                <div className="divBotonContinuar">
                  <Button
                    className="continuarBoton"
                    type="button"
                    label="Continuar"
                    disabled={!fechas}
                    onClick={clickFechaPendiente}
                  />
                </div>
              </form>
            </div>
          )}

          {/* fechaDefinida*/}
          {/* fechaDefinida*/}
          {estatusEvento === "fechaDefinida" && (
            <div className="formulario">
              <form onSubmit={enVivoLink.handleSubmit}>
                <div className="input">
                  <p className="textoInput">FECHA DEL EVENTO: </p>

                  <InputText
                    id="linkEnVivo"
                    name="linkEnVivo"
                    value={enVivoLink.values.linkEnVivo}
                    onChange={enVivoLink.handleChange}
                    placeholder="Link para en vivo"
                  />
                </div>

                <div className="divBotonContinuar">
                  <Button
                    className="continuarBoton"
                    type="button"
                    label="Rifa en proceso"
                    disabled={!enVivo}
                    onClick={clickEnProceso}
                  />
                </div>
              </form>
            </div>
          )}

          {/* enProceso*/}
          {/* enProceso*/}
          {estatusEvento === "enProceso" && (
            //asignar numero ganador
            <div className="formulario">
              <form onSubmit={boletoGanador.handleSubmit}>
                <div className="input">
                  <p className="textoInput">NUMERO GANADOR: </p>
                  <InputText
                    id="ganador"
                    name="ganador"
                    value={boletoGanador.values.ganador}
                    onChange={boletoGanador.handleChange}
                    placeholder="Numero ganador"
                  />
                </div>

                <div className="input">
                  <p className="textoInput">CONFIRMAR NUMERO GANADOR: </p>
                  <InputText
                    id="confirmarGanador"
                    name="confirmarGanador"
                    value={boletoGanador.values.confirmarGanador}
                    onChange={boletoGanador.handleChange}
                    placeholder="Confirmar"
                  />
                </div>

                <div className="divBotonContinuar">
                  <Button
                    className="continuarBoton"
                    type="button"
                    label="Continuar"
                    disabled={!numGanador}
                    onClick={clickBotonGanador}
                  />
                </div>
              </form>
            </div>
          )}

          {/* concluido*/}
          {/* concluido*/}
          {estatusEvento === "concluido" && (
            <div className="formulario">
              <div className="divBotonContinuar">
                <Button
                  className="continuarBoton"
                  type="button"
                  label="Conocer ganadores"
                  onClick={clickConocerGanadores}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
