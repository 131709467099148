export const Mazatlan = () => {
    return (
      <div className="imagenInicio">
        <p>
        --ACERCA DE MAZATLÁN
        --GASTRONOMÍA
        --PATRIMONIO
        --PREMIOS Y RECONOCIMIENTOS:
        --HECHOS TRASCENDENTES:

        </p>
      </div>
    );
  }