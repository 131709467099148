export const Instrucciones = () => {
    return (
      <div className="ContenedorInstrucciones">
        
          
        <div className="instrucciones">
        <img
                  className="imagenInstruccion"
                  src={"multimedia/instruccionesRifa/ConceptosRifas.jpg"}
                  alt="Concepto de las rifas"
                />
        <img
                  className="imagenInstruccion"
                  src={"multimedia/instruccionesRifa/ProcedimientoRifas.jpg"}
                  alt="procedimiento de las rifas"
                />
        <img
                  className="imagenInstruccion"
                  src={"multimedia/instruccionesRifa/DesarolloRifas.jpg"}
                  alt="procedimiento de las rifas"
                />
</div>
      
      </div>
    );
  };
  