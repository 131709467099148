import React from "react";
import { useFormik } from "formik";
import { useState } from "react";
import {
  iniciarSesion,
  verificarJWTSesion,
  consulEventosSesion,
  FechaEvento,
  enProcesoEvento,
  numGanador,
  conocerDatosGanadores,
} from "../../funciones/serviciosExteriores/PeticionesServidor";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import {
  actualizarCookie,
  eliminarCookie,
  VerificarCookies,
  valorCookie,
} from "../../funciones/cookies/cookies";
import Cargando from "../cargando/Cargando";
import { EditarEventos } from "./EditarEventos";
import { DatosGanadores } from "./DatosGanadores";

export const ModRifas = () => {
  const [botonContinuar, modBotonContinuar] = useState(false);
  const [sesionActiva, modSesionActiva] = useState(false);
  const [cargando, modCargando] = useState(false);
  const [datosObtenidos, modDatosObt] = useState([]);
  const [editarEvento, modEditarEvento] = useState(false);
  const [idEvento, modIdEvento] = useState("");
  const [nombreEvento, modNombreEvento] = useState("");
  const [estatusEvento, modEstatusEvento] = useState("");
  const [eventoDB, modEventoDB] = useState("");
  const [sesionGanadores, modSesionGanadores] = useState(false);
  const [datosGanadores, modDatosGanadores] = useState([]);

  React.useEffect(() => {
    if (VerificarCookies()) {
      modCargando(true);
      const consulta = verificarJWTSesion(valorCookie());
      consulta.then((respuesta) => {
        if (comprobarRespuesta(respuesta)) {
          modSesionActiva(true);
          consulEventos();
          modCargando(false);
        } else {
          respuestaNegativa();
        }
      });
    }
  }, []);

  ////////////////////////////////
  ////////////////////////////////
  const consulEventos = () => {
    const consulta = consulEventosSesion(valorCookie());
    consulta.then((respuesta) => {
      if (comprobarRespuesta(respuesta)) {
        let datos = respuesta["respuesta"];
        modDatosObt(datos.eventos);
      } else {
        respuestaNegativa();
      }
    });
  };

  ////////////////////////////////
  ////////////////////////////////

  const clickInicioSesion = () => {
    modCargando(true);
    formulario.resetForm();
    modBotonContinuar(false);
    const consulta = iniciarSesion(
      formulario.values.usuario,
      formulario.values.contrasenia
    );
    consulta.then((respuesta) => {
      if (comprobarRespuesta(respuesta)) {
        let JWT = respuesta["respuesta"];
        actualizarCookie(JWT.JWT);
        modSesionActiva(true);
        consulEventos();
        modCargando(false);
      } else {
        respuestaNegativa();
      }
    });
  };

  ////////////////////////////////
  ////////////////////////////////
  function clickFechaPendiente(props) {
    modCargando(true);
    const consulta = FechaEvento(
      props.fecha,
      props.idObjeto,
      nombreEvento,
      eventoDB,
      valorCookie()
    );

    consulta.then((respuesta) => {
      
      if (comprobarRespuesta(respuesta)) {
        consulEventos();
        modCargando(false);
      } else {
        respuestaNegativa();
      }
    });
  }

  ////////////////////////////////
  ////////////////////////////////

  function clickEnProceso(props) {
    modCargando(true);
    const consulta = enProcesoEvento(
      props.linkEnVivo,
      props.idObjeto,
      nombreEvento,
      eventoDB,
      valorCookie()
    );
    consulta.then((respuesta) => {
      if (comprobarRespuesta(respuesta)) {
        consulEventos();
        modCargando(false);
      } else {
        respuestaNegativa();
      }
    });
  }

  ////////////////////////////////
  ////////////////////////////////

  function clickBotonGanador(props) {
    modCargando(true);

    const consulta = numGanador(
      props.numGanador,
      props.idObjeto,
      nombreEvento,
      eventoDB,
      valorCookie()
    );
    consulta.then((respuesta) => {
      if (comprobarRespuesta(respuesta)) {
        consulEventos();
        modCargando(false);
      } else {
        respuestaNegativa();
      }
    });
  }

  ////////////////////////////////
  ////////////////////////////////

  function clickConocerGanadores(props) {
    modCargando(true);

    const consulta = conocerDatosGanadores(
      props.idObjeto,
      nombreEvento,
      eventoDB,
      valorCookie()
    );

    consulta.then((respuesta) => {
      if (comprobarRespuesta(respuesta)) {
        modSesionGanadores(true);
        let datos = respuesta["respuesta"];
        modDatosGanadores(datos.ganadores);
        modCargando(false);

      } else {
        respuestaNegativa();
      }
    });
  }

  //respuestas
  ////////////////////////////////
  ////////////////////////////////

  const comprobarRespuesta = (respuesta) => {
    if (respuesta["respuesta"].length === 0) {
      return false;
    } else {
      return true;
    }
  };

  ////////////////////////////////
  //respuesta negativa
  const respuestaNegativa = () => {
    eliminarCookie();
    modSesionActiva(false);
    modDatosObt([]);
    modSesionGanadores(false);
    modDatosGanadores([]);
    modCargando(false);
  };


  ////////////////////////////////
  ////////////////////////////////

  const clickBotonSeleccionarRifa = (
    eventoID,
    eventoEstatus,
    nombreEvento,
    eventoDB
  ) => {
    modIdEvento(eventoID);
    modEstatusEvento(eventoEstatus);
    modEventoDB(eventoDB);
    modNombreEvento(nombreEvento);
    modEditarEvento(true);
  };
////////////////////////////////
  ////////////////////////////////
  const clickCerrar = () => {
    modIdEvento("");
    modEstatusEvento("");
    modEventoDB("");
    modNombreEvento("");
    modEditarEvento(false);
  };
////////////////////////////////
  ////////////////////////////////
  const clickCerrarGanadores = () => {
    modSesionGanadores(false);
  };
  ////////////////////////////////
  ////////////////////////////////
  //formik
  const formulario = useFormik({
    initialValues: {
      usuario: "",
      contrasenia: "",
    },
    validate: (data) => {
      let errors = {};
      modBotonContinuar(false);
      if (data.usuario !== "" && data.contrasenia !== "") {
        modBotonContinuar(true);
      }
      return errors;
    },
  });

  return (
    <div>
      {cargando ? <Cargando></Cargando> : <></>}

      {sesionActiva && editarEvento ? (
        <EditarEventos
          idObjeto={idEvento}
          estatusEvento={estatusEvento}
          clickFechaPendiente={clickFechaPendiente}
          clickCerrar={clickCerrar}
          clickEnProceso={clickEnProceso}
          clickBotonGanador={clickBotonGanador}
          clickConocerGanadores={clickConocerGanadores}
        ></EditarEventos>
      ) : (
        <></>
      )}

      {sesionGanadores ? (
        <DatosGanadores
          clickCerrarGanadores={clickCerrarGanadores}
          datosGanadores={datosGanadores}
        ></DatosGanadores>
      ) : (
        <></>
      )}

      {sesionActiva && datosObtenidos !== [] ? (
        <div className="mostrarInputRifa">
          <div className="contenedorInput">
            <div className="bordesuperior"></div>

            {datosObtenidos !== [] &&
              datosObtenidos.map((cadaEvento) => {
                return (
                  <div key={cadaEvento.idObjeto} className="formulario">
                    <div className="input">
                      <p className="textoInput">
                        Nombre: {cadaEvento.nombreEvento}
                        <br></br>
                        Estatus: {cadaEvento.estatusEvento}
                        <br></br>
                        Costo boletos: ${cadaEvento.costoEvento}
                        <br></br>
                        Total de boletos: {cadaEvento.cantidadObjetos}
                        <br></br>
                        Boletos vendidos: {cadaEvento.objVendidos}
                      </p>
                      <div className="divBotonContinuar">
                        <Button
                          className="continuarBoton"
                          type="button"
                          label="Modificar"
                          onClick={(e) => {
                            clickBotonSeleccionarRifa(
                              cadaEvento.idObjeto,
                              cadaEvento.estatusEvento,
                              cadaEvento.nombreEvento,
                              cadaEvento.eventoDB
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="mostrarInputRifa">
          <div className="contenedorInput">
            <div className="bordesuperior"></div>

            <div className="formulario">
              <form onSubmit={formulario.handleSubmit}>
                <div className="input">
                  <p className="textoInput">USUARIO: </p>
                  <InputText
                    id="usuario"
                    name="usuario"
                    value={formulario.values.usuario}
                    onChange={formulario.handleChange}
                    placeholder="Usuario"
                  />
                </div>

                <div className="input">
                  <p className="textoInput">CONTRASEÑA: </p>
                  <Password
                    id="contrasenia"
                    name="contrasenia"
                    value={formulario.values.contrasenia}
                    onChange={formulario.handleChange}
                    placeholder="Contraseña"
                    toggleMask
                    feedback={false}
                  />
                </div>

                <div className="divBotonContinuar">
                  <Button
                    className="continuarBoton"
                    type="button"
                    label="Continuar"
                    disabled={!botonContinuar}
                    onClick={clickInicioSesion}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
