import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const MenuGeneral = (props) => {
  const navigate = useNavigate();
 
  //Botones principales//
  //Botones principales//
  //Botones principales//
  const [botonRifas, modBotonRifas] = useState(false);
  const [botonGeneral, modBotonGeneral] = useState(false);

  //////////////////////
  //GENERAL
  //////////////////////

  const botonA01 = "GENERAL";
  const linkbotonA01 = (event) => {
    event.stopPropagation();
    //navigate("/Contacto");
  };

  const botonA02 = "Instrucciones";
  const linkbotonA02 = (event) => {
    event.stopPropagation();
    navigate("/instrucciones");
  };

  const botonA03 = "Términos y condiciones";
  const linkbotonA03 = (event) => {
    event.stopPropagation();
    navigate("/terminos");
  };


  
  //////////////////////
  //RIFAS
  //////////////////////

  const botonB01 = "RIFAS";
  const linkbotonB01 = (event) => {
    event.stopPropagation();
    //navigate("/Rifas");
  };

  const botonB02 = "Veinte mil-1";
  const linkbotonB02 = (event) => {
    event.stopPropagation();
    navigate("/20mil-1");
    //window.location.assign("http://localhost:3000/rifaInicial");
    //window.location.assign("https://amomazatlan.com/rifaInicial");
  };

  return (
    <div className="menuPrincipalContenedor">
      {/*Inicia menu PC*/}
      {/*Inicia menu PC*/}

      <nav>
        <ul className="menuGeneral">
          {/*Rifas*/}
          <li className="listaPrincipal">
            <button
              type="button"
              className="botonesPrincipal"
              onClick={(e) => {
                linkbotonB01(e);
              }}
            >
              <span>{botonB01}</span>
            </button>
            <ul className="menu1erNivel">
              {/*Veintemil-1*/}
              <li className="lista1erNivel">
                <button
                  type="button"
                  className="botonesMenu"
                  onClick={(e) => {
                    linkbotonB02(e);
                  }}
                >
                  <span>{botonB02}</span>
                </button>
              </li>
            </ul>
          </li>

          {/*General*/}
          <li className="listaPrincipal">
            <button
              type="button"
              className="botonesPrincipal"
              onClick={(e) => {
                linkbotonA01(e);
              }}
            >
              <span>{botonA01}</span>
            </button>
            <ul className="menu1erNivel">
              {/*Instrucciones*/}
              <li className="lista1erNivel">
                <button
                  type="button"
                  className="botonesMenu"
                  onClick={(e) => {
                    linkbotonA02(e);
                  }}
                >
                  <span>{botonA02}</span>
                </button>
              </li>
              {/*Terminos*/}
              <li className="lista1erNivel">
                <button
                  type="button"
                  className="botonesMenu"
                  onClick={(e) => {
                    linkbotonA03(e);
                  }}
                >
                  <span>{botonA03}</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      {/*termina menu PC*/}
      {/*termina menu PC*/}

      {/*-------------*/}
      {/*-------------*/}
      {/*-------------*/}

      {/*Inicia menu para mobil*/}
      {/*Inicia menu para mobil*/}

      <button
        type="button"
        className="botonMenu botonHamburguesa"
        onClick={props.clickMenuCelular}
      >
        <i className="pi pi-bars" />
      </button>

      <div
        className={classNames("subMenu", {
          menuActivo: props.estadoMenuCelular,
        })}
      >
        <div className="columnaMenu">
          <h3 className="ocultar">
            <strong>MENÚ</strong>
          </h3>
          <div className="botonCerrar">
            <i className="pi pi-times ocultar" />
          </div>
        </div>

        <div className="columnaListado">
          <ul className="generalMenuCel">
            {/*Rifas*/}
            <li className="CelListaPrincipal">
              <button
                type="button"
                className="CelBotonesPrincipal"
                onClick={(e) => {
                  linkbotonB01(e);
                  modBotonRifas(!botonRifas);
                }}
              >
                <span>{botonB01}</span>
              </button>
              {/*Activar menu desplegable*/}
              {botonRifas && (
                <ul className="CelMenu1erNivel">
                  {/*RifaPrueba*/}
                  <li className="CelLista1erNivel">
                    <button
                      type="button"
                      className="CelBotonesMenu"
                      onClick={(e) => {
                        linkbotonB02(e);
                        props.clickSubMenuCelular(e);
                      }}
                    >
                      <span>{botonB02}</span>
                    </button>
                  </li>
                </ul>
              )}
            </li>

            {/*Contacto*/}
            <li className="CelListaPrincipal">
              <button
                type="button"
                className="CelBotonesPrincipal"
                onClick={(e) => {
                  linkbotonA01(e);
                  modBotonGeneral(!botonGeneral);
                }}
              >
                <span>{botonA01}</span>
              </button>
              {/*Activar menu desplegable*/}
              {botonGeneral && (
                <ul className="CelMenu1erNivel">
                  {/*Instrucciones*/}
                  <li className="CelLista1erNivel">
                    <button
                      type="button"
                      className="CelBotonesMenu"
                      onClick={(e) => {
                        linkbotonA02(e);
                        props.clickSubMenuCelular(e);
                      }}
                    >
                      <span>{botonA02}</span>
                    </button>
                  </li>
                  {/*Terminos*/}
                  <li className="CelLista1erNivel">
                    <button
                      type="button"
                      className="CelBotonesMenu"
                      onClick={(e) => {
                        linkbotonA03(e);
                        props.clickSubMenuCelular(e);
                      }}
                    >
                      <span>{botonA03}</span>
                    </button>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
