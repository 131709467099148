import { datosBoletos} from "../../interfaces/datosBoletos";
import { jwtDecode } from "jwt-decode";


export function decodificarJWT(JWT:string): datosBoletos{
  //para decodificar cookie JWT y asignarlo a un objeto
 
  let JWTDecodificado:datosBoletos = jwtDecode(JWT);
  
  return JWTDecodificado;
}
