

export const DatosGanadores = (props) => {
  let datosGanadores = props.datosGanadores

  const clickCerrar = () => {
    props.clickCerrarGanadores()
   };


  return (
    <div className="editarEventos">

    <div className="botonCerrar"
    onClick={clickCerrar}>

      <i className="pi pi-times" />
    
    </div>
   
    <div className="respuestaPago">
      <div className="contenedor">
      <div className="contenido">
  
        {datosGanadores.map((element) => {
                    return (
                      <div key={element.numeroBoleto} className="cadaParrafo">
                        <p className="textoInput">Premio: <strong>{element.tipoGanador}</strong></p>
                        <p className="textoInput">boleto: <strong>{element.numeroBoleto}</strong></p>
                        <p className="textoInput">Nombre: <strong>{element.nombreCliente}</strong></p>
                        <p className="textoInput">Celular: <strong>{element.celularCliente}</strong></p>
                        </div>
                    )
                    
                  })}

      </div>
      </div>
      </div>
      </div>
   

  );
};
