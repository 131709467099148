export const CentrosYLugares = () => {
  return (
    <div className="imagenInicio">
      <p>
        -parques -centros noturnos -museos --parques acuaticos --acuario
        --observatorio --centro comerciales mercados pino suarez juan carrasco
        juarez
      </p>
    </div>
  );
};
