import React from "react";
import { useFormik } from "formik";
import { useState } from "react";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { ValidarLada, ValidarCelular } from "../../../funciones/celular/VerificarCelular";
import { InputText } from "primereact/inputtext";

import { Checkbox } from 'primereact/checkbox';

export const InputDatosBoletos = (props) => {
  //let confirmarSeleccion = props.confirmarSeleccion;

  const [condicionCelular, modCondicionCelular] = useState(false);
  const [condicionNombre, modCondicionNombre] = useState(false);
  const [WhatsApp, modWhatsApp] = useState(false);

  const [botonContinuar, modBotonContinuar] = useState(false);
  const [error, modError] = useState(null);
  

  React.useEffect(() => {
    modBotonContinuar(false);

    if (
      condicionCelular &&
      WhatsApp &&
      condicionNombre &&
      props.condicionBoletosSeleccion
    ) {
      modBotonContinuar(true);
    }
  }, [
    condicionCelular,
    WhatsApp,
    condicionNombre,
    props.condicionBoletosSeleccion,
  ]);

  const formulario = useFormik({
    initialValues: {
      celular: "",
      numCelular: "",
      celularConfirmacion: "",
      lada: "",
      whatsAppAcepto: false,
      nombre: "",
    },

    validate: (data) => {
      let errors = {};

      modCondicionCelular(false);

      if (data.celular !== "" && data.celularConfirmacion !== "") {
        if (data.celular === data.celularConfirmacion) {
          data.numCelular = ValidarCelular(data.celular);

          if (data.numCelular !== "") {
            data.lada = ValidarLada(data.numCelular);

            if (data.lada === "false") {
              errors.celular = "(El número de celular no es nacional.)";
              modCondicionCelular(false);

              modError(true);
            } else {
              modCondicionCelular(true);
            }
          } else {
            errors.celular = "Número de celular incorrecto";
            modCondicionCelular(false);

            modError(true);
          }
        } else {
          errors.celular = "(Los celulares no coinciden.)";
          modCondicionCelular(false);

          modError(true);
        }
      }

      if (data.nombre !== "") {
        if (condicionNombre === false) {
          modCondicionNombre(true);
        }
      } else {
        modCondicionNombre(false);
      }

      if (data.whatsAppAcepto !== WhatsApp) {
        modWhatsApp(data.whatsAppAcepto);
      }

      return errors;
    },
  });

  const clickBotonContinuar = () => {
    const datosComprador = {
      nombre: formulario.values.nombre.toUpperCase(),
      celular: formulario.values.numCelular,
      lada: formulario.values.lada,
    };
    formulario.resetForm();
    modBotonContinuar(false);
    props.clickContinuarCompra(datosComprador);
  };

  return (
    <div className="contenedorInput">
      <div className="bordesuperior"></div>

      <div className="formulario">
        <form onSubmit={formulario.handleSubmit}>
          <div className="input">
            <p className="textoInput">NOMBRE: </p>
            <InputText
              id="nombre"
              name="nombre"
              value={formulario.values.nombre.toUpperCase()}
              onChange={formulario.handleChange}
              placeholder="Nombre"
            />
          </div>

          <div className="input">
            {error ? (
              <p>
                NÚMERO DE CELULAR:{" "}
                <a className="error">{formulario.errors.celular}</a>{" "}
              </p>
            ) : (
              <p className="textoInput">NÚMERO DE CELULAR:</p>
            )}
            <InputMask
              name="celular"
              id="celular"
              value={formulario.values.celular}
              mask="(999)-999-99-99"
              autoClear={false}
              unmask={false}
              placeholder="Solo ladas nacionales"
              onChange={formulario.handleChange}
            ></InputMask>
          </div>

          <div className="input">
            <p className="textoInput">CONFIRMACIÓN DE NÚMERO CELULAR:</p>
            <InputMask
              name="celularConfirmacion"
              id="celularConfirmacion"
              value={formulario.values.celularConfirmacion}
              mask="(999)-999-99-99"
              autoClear={false}
              unmask={false}
              placeholder="confirmación"
              onChange={formulario.handleChange}
            ></InputMask>
          </div>

          <div className="input">
            <p className="textoInput">
            ACEPTA, RECIBIR NOTIFICACIONES VIA WHATSAPP, ASÍ COMO LOS TÉRMINOS Y CONDICIONES:
            </p>

            <Checkbox className="checkbox" id="whatsAppAcepto"
              name="whatsAppAcepto" checked={formulario.values.whatsAppAcepto} onChange={formulario.handleChange} />
              <label htmlFor="whatsAppAcepto">SI, ACEPTO.</label>

                   
            {/* 
            <ToggleButton
              id="whatsAppAcepto"
              name="whatsAppAcepto"
              checked={formulario.values.whatsAppAcepto}
              onChange={formulario.handleChange}
              onLabel="SI, SI CUENTO Y ACEPTO."
              offLabel="NO, NO CUENTO."
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              aria-label="Confirmation"
            />
            */}
          </div>
          
          <div className="divBotonContinuar">
          {props.condicionBoletosSeleccion ? <></> : <p className="almenos1boleto">Seleccione al menos un boleto</p>}
            <Button
              className="continuarBoton"
              type="button"
              label="Continuar"
              disabled={!botonContinuar}
              onClick={clickBotonContinuar}
            />
          

          </div>
        </form>
      </div>
    </div>
  );
};

export default InputDatosBoletos;
