
export const DesplazarInput = (props) => {


    const desplazarInputRifa = (event) => {
        //window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        const inputDezplazar = document.getElementById("nombre");
        inputDezplazar.scrollIntoView({ behavior: "smooth", block: "center"});
    }

   
    return (
        <div className="desplazar">
            <button className="botonDesplazar p-link" onClick={desplazarInputRifa}>
                <i className="pi pi-arrow-down"></i>
            </button>
           

    
            </div>
       
    );
}
