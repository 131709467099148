export const Terminos = () => {
  return (
    <div className="ContenedorTerminos">
        <div className="Terminos">
      <h3 className="titulo">Términos y Condiciones de Uso y Privacidad</h3>
      <p>
        https://amomazatlan.com/ es utilizado para fines
        comerciales y de servicio.
        <br></br>
        <br></br>
        Al acceder o utilizar este Sitio Web, usted acepta estos Términos y
        Condiciones de Uso y Privacidad. Si usted no acepta los Términos y
        Condiciones de Uso, usted no puede utilizar este Sitio Web.
        <br></br>
        <br></br>
        https://amomazatlan.com, se compromete a ser imparcial, no actuar bajo
        conflicto de interés, ni dar algún trato preferente a los usurios.
        <br></br>
        <br></br>
        https://amomazatlan.com, declara, que los eventos de azar que se lleven
        a cabo en la presente web, serán totalmente transparentes y no existirá
        preferencia hacia ningún usuario o número de boleto especifico.
        <br></br>
        <br></br>
        https://amomazatlan.com, solicitará solo a las personas acreedoras a un
        premio, la información básica necesaria de tipo financiera o bancaria,
        con la finalidad de poder depositar o transferir el monto establecido previamente.
        <br></br>
        <br></br>
        https://amomazatlan.com, solicitará aceptación a todos los usuarios que esten
        interesados en adquirir un servicio, o en un trato comercial,
        para recibir notificaciones a través de la aplicación de WhatsApp (Meta)
        en cualquiera de sus modalidades (App de escritorio, Web, Móvil). Con el fin de,
        notificarle la situación de los productos o servicios adquiridos, así
        como, hacerle de su conocimiento cualquier cambio que presenten los mismo.
        <br></br>
        <br></br>
        https://amomazatlan.com, se compromete a no remitir ningún tipo de
        notificación vía WhatsApp (Meta), que este fuera de contexto referente al
        producto o servicio adquirido.
        <br></br>
        <br></br>
        El titular se reserva el derecho de solicitar el registro del
        Visitante para la Página Web, toda o parte de ella, que previamente haya
        sido de libre acceso y, en tal caso, está facultada, en cualquier
        momento y sin expresión de causa, a denegar al usuario el acceso al área
        protegida por contraseñas, en particular si el usuario:
        <br></br>
        1) Proporciona datos incorrectos con el fin de registrarse;
        <br></br>
        2) Incumple estos Términos y Condiciones de Uso y Privacidad;
        <br></br>
        3) Incumple cualquier normativa aplicable respecto del acceso o el uso
        de la presente Página Web.
        <br></br>
        <br></br>
        Este Sitio Web puede incluir conexiones a otros sitios web operados por
        compañías de terceros como MercadoPago. 
        <br></br>
        Estas conexiones
        son provistas para su comodidad y como una avenida de acceso adicional
        para las informaciones contenidas en los mismos. 
        <br></br>
        No hemos revisado toda
        la información contenida en otros sitios y no somos responsables por el
        contenido de otros sitios web ni por los productos o servicios que
        pudieran ser ofrecidos a través de otros sitios. Sitios web de terceros
        pueden contener información con la cual
        https://amomazatlan.com/ concuerda o no. Diferentes términos y
        condiciones pueden resultar aplicables al uso por nuestra parte de
        cualquiera de dichos sitios web conectados. Por favor tenga en
        consideración que los términos y condiciones de uso de otros sitios web
        son sustancialmente diferentes de estos términos y condiciones.
        Precisión, integridad y actualidad de la Información en este Sitio Web
        No somos responsables si las informaciones que estuvieran disponibles en
        este Sitio Web no fueran precisas, completas o actualizadas. El material
        en este Sitio Web es provisto solamente fines comerciales y de
        servicios, nuestro sitio esta encriptado cuenta con una IP Propia y un
        certificado SSL, que proporciona seguridad a los usuarios. Este Sitio
        Web puede contener cierta información histórica. Las informaciones
        históricas no son necesariamente actualizadas y son provistas únicamente
        para su referencia. Hacemos reserva del derecho de modificar el
        contenido de este Sitio Web en cualquier ocasión sin previo aviso.
        <br></br>
        <br></br>
        Este Sitio Web (incluyendo todo su contenido) es propiedad de https://amomazatlan.com/, el cual le autoriza a Ud. a consultar este Sitio
        Web y a imprimir y descargar copias del material del Sitio Web solamente
        para su uso personal.
        <br></br>
        <br></br>
        ello en la medida en que Ud. no elimine o remueva cualquier notificación
        o información de derechos de autor o propiedad intelectual que
        aparecieran en el material que Ud. imprimiera o descargara. Ud. acuerda
        que salvo lo expuesto precedentemente, no reproducirá, distribuirá,
        mostrará o transmitirá cualquier material en el Sitio Web, de cualquier
        manera y/o por cualquier medio. Ud. también acuerda no modificar,
        vender, transmitir o distribuir cualquier material en el Sitio Web, de
        cualquier manera o en cualquier medio, incluyendo la carga del material
        o de otro modo poner el material disponible en línea.
        <br></br>
        <br></br>
        El Sitio Web también puede incluir marcas comerciales o marcas de
        servicio de terceros. Todas esas marcas comerciales son propiedad de sus
        respectivos titulares y Ud. acuerda no usar o mostrar las mismas de
        cualquier forma sin la autorización previa por escrito del propietario
        de la marca comercial en cuestión.
        <br></br>
        <br></br>
        Contenido del visitante La Página Web podría permitir el upload de
        información, datos, textos, software, música, sonido, fotografías,
        gráficos, video, mensajes u otros materiales ("Contenido"), sea que se
        fijen públicamente o se transmitan privadamente. En tal caso, dicho
        Contenido es únicamente responsabilidad de la persona que lo originó.
        Esto significa que usted (https://amomazatlan.com) es enteramente
        responsable por todo el Contenido que usted cargue, publique, envíe por
        correo electrónico, transmita o de cualquier forma ponga a disposición a
        través del Sitio Web. Este sitio web, no controla el contenido publicado
        por Ud. , por tal motivo, no se garantiza su exactitud, integridad o
        calidad.
        <br></br>
        <br></br>
        No obstante, el Sitio Web contará con un moderador que estará facultado
        a eliminar el Contenido que le parezca impropio. Asimismo,
        https://amomazatlan.com eliminará todo Contenido ante la denuncia de
        aquellos terceros que se vean afectados y/o lesionados en sus derechos.
        Bajo ninguna circunstancia https://amomazatlan.com, será responsable en
        cualquier forma por cualquier Contenido, incluyendo, pero sin limitarse
        a cualquier error u omisión en cualquier Contenido, o por cualquier
        pérdida o daño de cualquier tipo ocasionado como resultado del uso de
        cualquier Contenido publicado, enviado a través de correo electrónico,
        transmitido o puesto a disposición a través del Sitio Web. Nosotros nos
        reservamos el derecho de eliminar el acceso y/o uso del Sitio Web a
        cualquier usuario y/o visitante del mismo (“Visitante”) que no respete
        los términos y condiciones establecidos en el presente.
        <br></br>
        <br></br>
        Asimismo, https://amomazatlan.com, se reserva el derecho de anular
        cualquier servicio o pacto comercial, en donde se cumpla cualquiera de
        los siguientes puntos: • Sea ilegal, peligroso, amenazante, abusivo,
        hostigador, tortuoso, difamatorio, vulgar, obsceno, calumnioso, invasivo
        de la privacidad de terceros, odioso, discriminatorio, o que de
        cualquier forma violente derechos de terceros y/o disposiciones legales
        aplicables;
        <br></br>• Sea contrario a la moral y las buenas costumbres. Usted se
        obliga a no utilizar el Sitio Web para lo siguiente:
        <br></br>• Dañar a menores de edad en cualquier forma;
        <br></br>• Hacerse pasar por alguna persona o entidad o hacer
        declaraciones falsas, o de cualquier otra forma falsificar su asociación
        a alguna persona o entidad;
        <br></br>• Falsificar encabezados o de cualquier otra forma manipular
        identificadores para desviar el origen de algún Contenido transmitido
        por medio del Sitio Web;
        <br></br>• Cargar ("upload"), publicar, enviar por correo electrónico,
        transmitir, o de cualquier otra forma poner a disposición algún
        Contenido del cual no tiene el derecho de transmitir por ley o bajo
        relación contractual o fiduciaria (tal como información interna, de
        propiedad y confidencial adquirida o entregada como parte de las
        relaciones de empleo o bajo contratos de confidencialidad);
        <br></br>• Cargar, publicar, enviar por correo electrónico, transmitir,
        o de cualquier otra forma poner a disposición algún Contenido que viole
        alguna patente, marca, secreto comercial, derecho de autor o cualquier
        derecho de propiedad ("Derechos") de algún tercero;
        <br></br>• Cargar, publicar, enviar por correo electrónico, transmitir o
        de cualquier otra forma poner a disposición cualquier anuncio no
        solicitado o no autorizado, materiales promocionales, correo no
        solicitado ("junk mail", "spam"), cartas en cadena ("chain letters"),
        esquemas de pirámides ("pyramid schemes") o cualquier otra forma de
        solicitud, con excepción de aquellas áreas (tales como cuartos de
        compras, "shopping rooms") que están destinadas para tal propósito
        <br></br>• Cargar ("upload"), publicar, enviar por correo electrónico,
        transmitir, o de cualquier otra forma poner a disposición algún material
        que contenga virus de software, o cualquier otro código de computadora,
        archivos o programas diseñados para interrumpir, destruir o limitar el
        funcionamiento de algún software, hardware o equipo de
        telecomunicaciones;
        <br></br>• Interrumpir el flujo normal de diálogo, hacer que una
        pantalla se mueva ("scroll") más rápido de lo que otros Visitantes
        pueden manejar, o de cualquier otra forma actuar de manera que afecte
        negativamente la habilidad de otros Visitantes para vincularse en
        intercambios de tiempo reales;
        <br></br>• Interferir o interrumpir el Sitio Web, servidores, o redes
        conectadas al Sitio Web, o desobedecer cualquier requisito,
        procedimiento, política o regulación de redes conectadas al Sitio Web;
        <br></br>• Violar con o sin intención alguna ley local, estatal,
        nacional o internacional aplicable y cualquier otra regulación;
        <br></br>• Acechar o de cualquier otra forma hostigar a un tercero;
        <br></br>• Colectar o guardar datos personales acerca de otros
        Visitantes.
        <br></br>• Publicar datos personales sin el consentimiento de la persona
        involucrada. https://amomazatlan.com en ningún caso será responsable por
        la destrucción o eliminación de la información que los Visitantes
        incluyan en sus mensajes.
        <br></br>
        <br></br>
        Datos personales es cualquier información que permite identificar a un
        individuo. 
        <br></br>
        Las clases de datos personales que el presente sitio puede
        recolectar incluyen el nombre y el apellido de la persona, domicilio,
        número de teléfono, domicilio de entrega, y dirección de correo
        electrónico (datos no sensibles). 
        <br></br>
        <br></br>
        El titular de este sitio no recabará
        ningún dato personal sobre su persona a menos que Ud. voluntariamente lo
        provea o de otro modo lo permita la normativa aplicable sobre protección
        de datos personales.
        <br></br>
        
         En consecuencia, quien provee los datos reconoce
        que proporciona sus datos en forma absolutamente voluntaria y que los
        mismos son ciertos. 
        
        <br></br>
        <br></br>
        https://amomazatlan.com, solicitara solo a las
        personas acreedoras a un premio, la información básica necesaria de tipo
        financiera o bancaria, con la finalidad de poder depositar o transferir
        el monto, con previo acuerdo establecido. Todo mensaje que solicite una
        información más específica, dicha información no será de
        https://amomazatlan.com. 
        
        <br></br>
        <br></br>
        
        El ingreso de los datos personales implica el
        consentimiento del Visitantes a ceder sus datos y ser parte de la base
        de datos de https://amomazatlan.com.
        
        <br></br>
        <br></br>
        Aquellos Usuarios que deseen tener acceso a
        sus datos personales y/o eliminarlos de la base de datos, deberán
        comunicarse al celular 6692168042.
        
        Si
        Ud. usa las características de comunicación de este Sitio Web para
        proveernos otra información, más allá de su información personal y datos
        identificatorios, incluyendo sugerencias acerca del Sitio Web, ideas
        sobre productos y publicidad, y cualquier otra información relacionada,
        tales informaciones pasan a pertenecer en forma gratuita a nosotros y
        pueden ser usadas, reproducidas, modificadas, distribuidas y divulgadas
        por nosotros de cualquier forma que escojamos.
        <br></br>
        <br></br>
        https://amomazatlan.com/  NO DECLARA NI
        GARANTIZA QUE EL CONTENIDO DEL SITIO WEB ES EXACTO Y COMPLETO.
        
        <br></br>
        <br></br> ESTE
        SITIO WEB Y EL MATERIAL, LA INFORMACIÓN, LOS SERVICIOS Y LOS PRODUCTOS
        EN ESTE SITIO WEB, INCLUYENDO, SIN CARÁCTER LIMITATIVO, EL TEXTO, LOS
        GRÁFICOS Y LOS ENLACES SE PROPORCIONAN EN EL ESTADO EN QUE SE ENCUENTRAN
        Y SIN GARANTÍAS DE NINGÚN TIPO, YA SEAN EXPRESAS O IMPLÍCITAS. 
        <br></br>
        <br></br>
        EN LA
        MAYOR MEDIDA PERMITIDA CONFORME A LA LEY APLICABLE,
        https://amomazatlan.com/  NIEGA TODA GARANTÍA, EXPRESA O IMPLÍCITA,
        INCLUYENDO, PERO SIN CARÁCTER LIMITATIVO, TODAS LAS GARATÍAS DE
        COMERCIALIZACIÓN O DE ADECUACIÓN PARA UN FIN ESPECÍFICO, DE NO
        VIOLACIÓN, DE PRODUCTO LIBRE DE VIRUS INFORMÁTICOS, Y TODA GARANTÍA QUE
        SURJA EN EL CURSO DE LA OPERACIÓN O DURANTE EL CUMPLIMIENTO DE LA MISMA.

        <br></br>
        <br></br>
        https://amomazatlan.com/ NO DECLARA NI GARANTIZA QUE LAS FUNCIONES
        CONTEMPLADAS EN EL SITIO WEB SERÁN ININTERRUMPIDAS O QUE ESTARÁN LIBRES
        DE ERRORES, QUE LOS DEFECTOS SERÁN CORREGIDOS O QUE ESTE SITIO WEB O EL
        SERVIDOR QUE HACE QUE EL SITIO WEB ESTÉ DISPONBILE ESTÁN LIBRES DE VIRUS
        U OTROS ELEMENTOS DAÑINOS. 
        <br></br>
        <br></br>
        https://amomazatlan.com/  NO EFECTÚA NINGUNA
        DECLARACIÓN O GARANTÍA RESPECTO DEL USO DEL MATERIAL EN ESTE SITIO WEB
        EN CUANTO A SI EL MATERIAL ES COMPLETO, CORRECTO, EXACTO, ADECUADO,
        ÚTIL, OPORTUNO, CONFIABLE. 
        
        <br></br>
        ADEMÁS DE LO PRECEDENTEMENTE MENCIONADO,
        USTED (Y NO https://amomazatlan.com/ ) ASUME TODO EL COSTO DE TODOS LOS
        SERVICIOS, REPARACIONES O CORRECCIONES QUE FUERAN NECESARIOS. 
        
        <br></br>
        <br></br>
        UD. ENTIENDE Y ACUERDA QUE, https://amomazatlan.com/ , NI
        CUALQUIERA DE SUS RESPECTIVAS SUBSIDIARIAS O AFILIADAS O TERCEROS
        PROVEDORES DE CONTENIDO SERAN RESPONSABLES POR CUALQUIER DAÑO DIRECTO,
        INDIRECTO, INCIDENTAL, ESPECIAL, MEDIATO, INMEDIATO, CONSECUENTE,
        PUNITIVO O CUALQUIER OTRO, RELATIVOS A O RESULTANTES DE SU USO O SU
        INCAPACIDAD DE USAR ESTE SITIO WEB O CUALQUIER OTRO SITIO WEB QUE UD.
        ACCEDIERA A TRAVÉS DE UNA CONEXIÓN A PARTIR DE ESTE SITIO WEB O DE
        CUALQUIER MEDIDA QUE TOMEMOS O DEJEMOS DE TOMAR COMO RESULTADO DE
        MENSAJES DE CORREO ELECTRONICO QUE UD. NOS ENVIE. ESTOS INCLUYEN DAÑOS
        POR ERRORES, OMISIONES, INTERRUPCIONES, DEFECTOS, ATRASOS, VÍRUS
        INFORMÁTICOS, SU LUCRO CESANTE, PERDIDA DE DATOS, ACCESO NO AUTORIZADO A
        Y ALTERACIÓN DE SUS TRANSMISIONES Y DATOS, Y OTRAS PERDIDAS TANGIBLES E
        INTANGIBLES. 
        <br></br>
        ESTA LIMITACIÓN RESULTA APLICABLE INDEPENDIENTEMENTE DE SI
        LOS DAÑOS Y PERJUICIOS FUERAN RECLAMADOS EN VIRTUD DE UN CONTRATO, COMO
        RESULTADO DE NEGLIGENCIA O DE OTRO MODO, E IGUALMENTE SI NOSOTROS O
        NUESTROS REPRESENTANTES HUBIEREN SIDO NEGLIGENTES O HUBIEREN SIDO
        INFORMADOS SOBRE LA POSIBILIDAD DE TALES DAÑOS.
        
        <br></br>
        <br></br> SI
        UD. CAUSARA UNA INTERRUPCIÓN TÉCNICA DE ESTE SITIO WEB O DE LOS SISTEMAS
        QUE TRANSMIETEN EL SITIO WEB A UD. Y A OTROS, UD. ASUME LAS
        RESPONSABILIDAD POR TODAS Y CUALQUIER RESPONSABILIDADES, COSTOS Y GASTOS
        (INCLUYENDO HONORARIOS DE ABOGADOS) QUE SURGIERAN COMO CONSECUENCIA DE
        DICHA INTERUPCIÓN.
        <br></br>
        <br></br>
        https://amomazatlan.com/  tiene sede en Mazatlán, Sinaloa
        Mexico y este Sitio Web es operado en toda la república mexicana. 
        <br></br>
        <br></br>
        Ud. acepta en forma irrevocable la jurisdicción de los tribunales de Mexico,
        en relación con cualquier acción para la ejecución de estos términos y
        condiciones. Reconocemos que es posible que Ud. obtenga acceso a este
        Sitio Web desde cualquier lugar en el mundo, pero no tenemos capacidad
        práctica para impedir tal acceso. 
        <br></br>
        <br></br>
        Este Sitio Web fue proyectado para
        cumplir con las leyes de Mexico. Los negocios llevados a cabo por las
        afiliadas da https://amomazatlan.com/ operan en varias zonas de Mexico,
        nos limitamos a solamente nuestras sucursales, mismas que estén
        relacionadas con el RFC mencionado anteriormente.
        <br></br>
        <br></br>
        Si cualquier material
        en este Sitio Web, o el uso de este Sitio Web por Ud. fuera contrario a
        las leyes del lugar en el cual Ud. estuviera al acceder al mismo, el
        Sitio Web no está destinado a Ud. y le solicitamos que no utilice el
        Sitio Web. Ud. es responsable por informarse respecto a las leyes de su
        jurisdicción y por el cumplimiento de las mismas. 
        <br></br>
        <br></br>
        Usted acepta que https://amomazatlan.com/ puede, bajo ciertas circunstancias y
        sin necesidad de notificación previa, cancelar y terminar inmediatamente
        su posibilidad de acceso al Sitio Web. <br></br>Entre las causales de terminación
        se incluyen, sin limitarse: 
        <br></br>(a) incumplimientos o violaciones a estos
        términos y condiciones; 
        <br></br>
        (b) requerimientos de autoridades legales o
        gubernamentales;
        <br></br>
        (c) su solicitud (terminación de cuenta por
        requerimiento del Visitante);
        <br></br>
        (d) terminación o modificaciones
        sustanciales al Servicio (o cualquier parte del mismo);
        <br></br>
        (e) problemas
        técnicos o de seguridad inesperados;
        <br></br>
        (f) períodos de inactividad
        prolongados. 
        <br></br>
        <br></br>
        Asimismo, usted acepta que todas las causales de
        terminación con causa podrán ser invocadas por nosotros a nuestra sola
        discreción y que no seremos responsables frente a usted ni frente a
        ningún tercero por cualquier terminación de su cuenta, y las direcciones
        de correo electrónico asociada o acceso al Sitio Web.
        <br></br>
        <br></br>
        Modificaciones a
        estos Términos Hacemos reserva del derecho, a nuestro exclusivo y
        completo criterio, de alterar estos términos y condicionas en cualquier
        ocasión, mediante la muestra en el Sitio Web de nuevos términos y
        condiciones.
        <br></br>
        <br></br>
         Es su responsabilidad verificar periódicamente cualquier
        alteración que pudiéramos realizar sobre estos términos y condiciones.
        <br></br>
        <br></br>
        Su uso continuado de este Sitio Web luego de la presentación de nuevos
        términos y condiciones implicará y significará su aceptación a las
        modificaciones introducidas. Gracias por visitar nuestro Sitio Web.
      </p>
    </div>
    </div>
  );
};
