export const Trascendentes = () => {
    return (
      <div className="imagenInicio">
        <p>
        -eclipse solar
-manifestacion banda
-nombramiento heroico
-seleccion mexicana
</p>
      </div>
    );
  }